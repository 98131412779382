import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { useForm, Controller } from "react-hook-form";
import JoditEditor, { Jodit } from 'jodit-react';
import { toast, confirm } from '@rickylandino/react-messages';
import { getRepEmailTemplates, deleteRepEmailTmplate, addRepEmailTemplate, updateRepEmailTemplate } from '../../services/RepEmailTemplateService';
import { useDropzone } from 'react-dropzone';
import { UploadOutlined } from '@ant-design/icons';
import { Select, Upload, Button, message } from 'antd';
import Axios from '../../config/axios';
import { sendRepEmail } from '../../services/EmailSenderService';
import { insertCustomerNote } from '../../services/NotesService';
import { EmailValidator } from 'node-email-validation';
import { convertHtmlToText } from '../Helpers/ManipulateHTML';
import { useRecoilState } from 'recoil';
import { persistentNotes } from '../../services/PersistNotes';
import { persistentFilteredNotes } from '../../services/PersistNotes';
import { notesFilterType } from '../../services/PersistNotes';

export default function SimpleSendEmailSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        selectedSendToEmails: []
    });

    const { Option } = Select;
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [contacts, setContacts] = useState([]);

    const { register, getValues, setValue, control, watch } = useForm();
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    
    //useEffect(() => {
    //    setState({
    //        ...state,
    //        showPane: props.showPane,
    //        dataLoaded: true
    //    });

    //}, [props]);

    useEffect(() => {
        console.log(props.selectedCustomerId);

        if (props.showPane) {
            setState({
                ...state,
                dataLoaded: false
            });

            let postdata = {};
            postdata.company_id = props.selectedCustomerId;
            postdata.jobshopdivision = userInfo.jobShopDivision;

            Axios.post(`/api/GetCompanyContacts`, postdata
            ).then(response => {
                let contacts = response.data;
                console.log(contacts);

                setContacts(contacts);

                setState({
                    ...state,
                    dataLoaded: true
                });

            }).catch(error => {
                console.log(error);
            });
        }
    }, [props.showPane]);

    useEffect(() => {
        if (state.dataLoaded === true) {
            setValue('templateName', '');
            //setValue('sendTo', props.emailInformation.selectedContact.email);
            setValue('emailSubject', '');
            setValue('saveToNotes', true);
            setValue('saveToImap', true);
            setContent('');

            setState({
                ...state,
                showPane: true
            });
        }
    }, [state.dataLoaded]);

    function hidePane() {
        setState({
            ...state,
            showPane: false,
            dataLoaded: false
        });
        
        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    function getCompanyContacts(company_id, jobshopdivision) {
        
    }

    function handleSendEmail() {
        if (getValues().sendTo.trim() === '' || getValues().emailSubject.trim() === '' || content.trim() === '') {
            toast.error("Email To, Subject and Body Content are required fields");

            return;
        }

        // Validate list of sendTo and sendCC email addresses
        var validator = require("node-email-validation");
        var splitList;

        //Validate list of sendTo email addresses
        if (getValues().sendTo.includes(";")) {
            splitList = getValues().sendTo.split(';');
        }
        else if (getValues().sendTo.includes(",")) {
            splitList = getValues().sendTo.split(',');
        }
        if (splitList === undefined) {
            if (validator.is_email_valid(getValues().sendTo) === false) {
                toast.error("Invalid format found for 'To' address");
                return;
            }
        }
        else {
            for (let i = 0; i < splitList.length; i++) {
                if (validator.is_email_valid(splitList[i].trim()) === false) {
                    toast.error("Invalid format found for 'To' addresses");
                    return;
                }
            }
        }

        //Validate list of sendCc email addresses
        if (getValues().sendCc.trim() !== '') {
            if (getValues().sendCc.includes(";")) {
                splitList = getValues().sendCc.split(';');
            }
            else if (getValues().sendCc.includes(",")) {
                splitList = getValues().sendCc.split(',');
            }
            if (splitList === undefined) {
                if (validator.is_email_valid(getValues().sendCc) === false) {
                    toast.error("Invalid format found for 'Cc' address");
                    return;
                }
            }
            else {
                for (let i = 0; i < splitList.length; i++) {
                    if (validator.is_email_valid(splitList[i].trim()) === false) {
                        toast.error("Invalid format found for 'Cc' addresses");
                        return;
                    }
                }
            }
        }

        const key = "sendEmail";
        message.loading({
            content: 'Sending Email...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        sendRepEmail(userInfo.userId, getValues().sendTo, getValues().sendCc, getValues().emailSubject, content, getValues().selectedRepDocuments, fileList, getValues().saveToImap).then(data => {
            if (getValues().saveToNotes === true) {
                var customerId;
                if (props.emailInformation) {
                    customerId = props.emailInformation.selectedCustomer.id;
                }
                else {
                    customerId = props.selectedCustomer.id;
                }

                var note = "";
                if (content.includes("<div id=\"signature\"")) {
                    note = content.substring(0, content.indexOf("<div id=\"signature\""));
                }
                else {
                    note = content;
                }

                note = convertHtmlToText(note);

                var note_date = new Date();
                var division = userInfo.jobShopDivision;
                var userId = userInfo.userId;
                var pinned = false;
            }

            message.destroy(key);

            if (data.includes("Success:")) {
                toast.success(data.replace("Success:", ""));
            }
            else if (data.includes("Error:")) {
                toast.error(data.replace("Error:", ""));
            }
            else {
                toast.info(data.replace("Info:", ""));
                hidePane();
            }

        }).catch(error => {
            toast.error("Problem encountered sending email");
        });
    }

    var joditButtons = [...Jodit.defaultOptions.buttons];
    
    const fileprops = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
                toast.error(`${file.name} is not a PDF file. Only PDF files are allowed`);
                return false;
            }
            const newFileList = [...fileList, file];
            setFileList(newFileList);

            return false;
        },
        fileList,
    };

    function handleSendToChange(value) {
        console.log(value);

        setState({
            ...state,
            selectedSendToEmails: value
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='D2P Web CM Email Sender'
            onRequestClose={hidePane}
            width={'75%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <Fragment>
                            <div className="row">
                                <div className="form-group col-6">
                                    {/*<label>To:</label>*/}
                                    {/*<input className="ant-input" {...register('sendTo')} />*/}

                                    <label>To:</label>
                                    <Select mode="multiple" className="form-control-custom d-inline-block" onChange={handleSendToChange} style={{ width: '100%', marginTop: 7 }} placeholder="Select one or more past shows" value={state.selectedServiceCodes || undefined} >
                                    {contacts?.map((c, idx) => <option key={idx} value={c.contact_id}>{c.jsS_name}</option>)}
                                    </Select>
                                </div>

                                <div className="form-group col-6">
                                    <label>Cc:</label>
                                    <input className="ant-input" {...register('sendCc')} />
                                </div>

                                <div className="form-group col-lg-12">
                                    <label>Email Subject</label>
                                    <input className="ant-input" {...register('emailSubject')} />
                                </div>

                                <div className="form-group col-lg-6">
                                <label>{state.fileToAttach}</label>
                                    
                                </div>
                                <div className="form-group col-lg-6">
                                    <label>Other File Attachments</label>
                                    <Upload {...fileprops} className="custom-upload row" accept=".pdf">
                                        <Button icon={<UploadOutlined />}>Click to Attach</Button>
                                    </Upload>
                                </div>
                                
                                <div className="col col-12">
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        tabIndex={1} // tabIndex of textarea
                                        onBlur={(newContent) => { setContent(newContent); }} // preferred to use only this option to update the content for performance reasons
                                        onChange={(newContent) => { }}
                                        config={{
                                            height: "450",
                                            allowResizeY: true,
                                            uploader: {
                                                insertImageAsBase64URI: true
                                            },
                                            buttons: joditButtons,
                                            removeButtons: ['source', 'about', 'video', 'file', 'fullsize', 'preview', 'image'],
                                        }}
                                    />
                                </div>
                            </div>
                            {/*<div class="align-middle">*/}
                                <div className="row container">
                                    <div className="col col-2 mt-3">
                                        <button className="ant-btn ant-btn-primary" onClick={handleSendEmail}>Send Email</button>
                                    </div>
                                    <div className="col col-10"></div>
                                </div>

                            {/*</div>*/}
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>

        </SlidingPane>
    );
}