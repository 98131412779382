import { toast } from '@rickylandino/react-messages';
import { Drawer, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { useForm } from "react-hook-form";
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { getChangeRequestByWorklistId } from '../../services/WorklistChangeRequestsService';
import { insertNewWorklistItem } from '../../services/WorklistService';
import { ApproveDenyOrRequestMoreInfoButton } from '../Dashboard/Buttons/ApproveDenyOrRequestMoreInfoButton';

export default function JSS_SalesOrderWFSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        showListLoaded: false,
        showList: [],
        initialShowList: [],
        searchForm: {},
        searchFormInitialState: {},
        submitButtonText: ''
    });

    const { register, getValues, setValue, control, watch } = useForm();


    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    var isDirty = useRef(false);

    useEffect(() => {
        console.log(props);

        if (props.selectedCustomerId) {
            GetFutureShowList(props.selectedCustomerId);
        } else if (props.selectedWorklistItem && props.showPane) {
            displayChangeRequests();
        }
    }, []);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    async function displayChangeRequests() {
        /*
         *   if this slider is being viewed from the worklist dashboard
         *   set the formfields to the contact details with the pending 
         *   change requests highlighted
         */
        let w = props.selectedWorklistItem;

        var salesOrder = JSON.parse(w.newValue);

        const lowerCase = str => str[0].toLowerCase() + str.slice(1);
        salesOrder = Object.fromEntries(Object.entries(salesOrder).map(
            ([k, v]) => [lowerCase(k), v])
        );
        var notes = salesOrder.searchForm.notes;
        var showList = salesOrder.searchForm.showList;

        let futureShows = [];
        for (var i = 0, l = showList.length; i < l; i++) {
            var show = showList[i];
            var bgColor;
            var invoicedColor;
            if (show.salesstatus === '') {
                bgColor = '';
            }
            else {
                bgColor = 'yellow';
            }

            if (show.invoiced === 'Yes') {
                show.invoiced = 'Invoiced';
                invoicedColor = 'green';
            }
            else {
                show.invoiced = 'Not Invoiced';
                invoicedColor = 'red';
            }

            const futureshow = {
                salesstatus: show.salesstatus, name: <span style={{ backgroundColor: bgColor }}>{show.name}</span>, city: show.city, startdate: show.startdate, finishdate: show.finishdate, showcode: show.showcode, booth: show.booth, invoiced: <span style={{ color: invoicedColor }}>{show.invoiced}</span>, selectedpackage: show.selectedpackage, weblink: show.weblink, noshowleads: show.noshowleads, leadretrieval: show.leadretrieval
            };
            futureShows.push(futureshow);
        }

        setState({
            ...state,
            showList,
            futureShows,
            searchForm: {
                ...state.searchForm,
                notes: notes
            },
            dataLoaded: true,
            submitButtonText: 'Process Workflow',
            showPane: true
        })      
    }

    function GetFutureShowList(selectedCustomerId) {
        Axios.get(`/api/GetShowsForNewSalesOrder`, {
            params: {
                companyid: selectedCustomerId
            }
        }).then(response => {
            const showList = response.data;

            let futureShows = [];

            for (var i = 0, l = showList.length; i < l; i++) {
                var show = showList[i];

                const futureshow = {
                    salesstatus: '', name: show.name, city: show.city, startdate: show.startdate, finishdate: show.finishdate, showcode: show.showcode, booth: show.booth, invoiced: show.invoiced, selectedpackage: '', weblink: '', noshowleads: '', leadretrieval: ''
                };
                futureShows.push(futureshow);
            }

            setState({
                ...state,
                showListLoaded: true,
                dataLoaded: true,
                showList,
                initialShowList: showList,
                futureShows,
                searchForm: {},
                searchFormInitialState: state.searchForm,
                submitButtonText: 'Submit Workflow',
                showPane: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleSubmitRealWorkflow() {
        let errString = '';
        let showsSelected = 0;

        for (var i = 0, l = state.futureShows.length; i < l; i++) {
            var show = state.futureShows[i];

            if (show.salesstatus !== '') {
                showsSelected += 1;

                if (show.selectedpackage === '') {
                    errString += 'A package type must be selected for ' + show.name + '<br />';
                }
                if (show.salesstatus === 'Yes' && show.invoiced === 'Yes' && show.booth === null) {
                    errString += show.name + ' has been invoiced - a booth # must be provided <br />';
                }
            }
        }
        if (showsSelected === 0) {
            errString += 'At least one show must be selected<br />';
        }

        if (errString !== '') {
            var htmlErrorContent = <div dangerouslySetInnerHTML={{ __html: errString }} />

            toast.error(htmlErrorContent);
            return;
        }

        let wfStateInitial = {};
        wfStateInitial.searchFormInitialState = state.searchFormInitialState;
        wfStateInitial.searchFormInitialState.showList = state.initialShowList;

        let wfState = {};
        wfState.searchForm = state.searchForm;
        wfState.searchForm.showList = state.futureShows;

        let postdata = {
            originatorUsername: userInfo.userId,
            company_Id: props.selectedCustomerId,
            itemDetail: "Process JSS Sales Order",
            oldValue: JSON.stringify(wfStateInitial),
            newValue: JSON.stringify(wfState)
        }

        insertNewWorklistItem(postdata).then(data => {
            if (data) {
                toast.success("Worklist item added successfully");

                setState({
                    ...state,
                    showPane: false
                });
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });

        //Axios.post(`/api/SendSalesOrderNotificationEmail`, postdata
        //).then(response => {
        //    if (response.data === true) {
        //        hidePane();
        //        toast.success("Sales Order Processed Successfully");
        //    }
        //    else {
        //        toast.error("Error Processing Sales Order");
        //    }
        //}).catch(error => {
        //    console.log(error);
        //});    
    }

    //original workflow to simply send email
    function handleSubmitWorkflow() {
        let errString = '';
        let showsSelected = 0;

        for (var i = 0, l = state.futureShows.length; i < l; i++) {
            var show = state.futureShows[i];

            if (show.salesstatus !== '') {
                showsSelected += 1;

                if (show.selectedpackage === '') {
                    errString += 'A package type must be selected for ' + show.name + '<br />';
                }
                if (show.salesstatus === 'Yes' && show.invoiced === 'Yes' && show.booth === null) {
                    errString += show.name + ' has been invoiced - a booth # must be provided <br />';
                }
            }
        }
        if (showsSelected === 0) {
            errString += 'At least one show must be selected<br />';
        }

        if (errString !== '') {
            var htmlErrorContent = <div dangerouslySetInnerHTML={{ __html: errString }} />

            toast.error(htmlErrorContent);
            return;
        }

        let postdata = {
            submittedby: userInfo.userName,
            company_id: props.selectedCustomerId,
            company: props.selectedCustomerName,
            note: state.searchForm.notes,
            showsList: state.futureShows
        }

        Axios.post(`/api/SendSalesOrderNotificationEmail`, postdata
        ).then(response => {
            if (response.data === true) {
                hidePane();
                toast.success("Sales Order Processed Successfully");
            }
            else {
                toast.error("Error Processing Sales Order");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name.includes("showname")) {
            var showList = state.showList;
            for (var i = 0, l = showList.length; i < l; i++) {
                if (showList[i].formkey == name) {
                    showList[i].formstatus = value;

                    setState({
                        ...state,
                        showList
                    });

                    break;
                }
            }
        }
        else {
            setState({
                ...state,
                searchForm: { ...state.searchForm, [name]: value }
            });
        }    
    }

    const handleOptionChange = (selectedOption, showname) => {
        let futureShows = [...state.futureShows];
        var objectToChangeIdx = futureShows.findIndex(show => show.name === showname);
        var objectChanged = { ...futureShows[objectToChangeIdx] };

        //toggles the selection
        switch (selectedOption) {
            case 'weblink':
                if (objectChanged.weblink === '') {
                    objectChanged.weblink = 'Yes';
                }
                else {
                    objectChanged.weblink = '';
                }
                break;
            case 'noshowleads':
                if (objectChanged.noshowleads === '') {
                    objectChanged.noshowleads = 'Yes';
                }
                else {
                    objectChanged.noshowleads = '';
                }
                break;
            case 'leadretrieval':
                if (objectChanged.leadretrieval === '') {
                    objectChanged.leadretrieval = 'Yes';
                }
                else {
                    objectChanged.leadretrieval = '';
                }
                break;
        }

        futureShows[objectToChangeIdx] = objectChanged;

        setState({
            ...state,
            futureShows
        });
    }

    const handleSalesStatusChange = (selectedOption, showname) => {
        let target = selectedOption.target;
        let name = target.name;
        let value = target.value;

        let futureShows = [...state.futureShows];
        var objectToChangeIdx = futureShows.findIndex(show => show.name === showname);
        var objectChanged = { ...futureShows[objectToChangeIdx] };

        objectChanged.salesstatus = value;

        futureShows[objectToChangeIdx] = objectChanged;

        setState({
            ...state,
            futureShows
        });
    };

    const handlePackageTypeChange = (packagetype, showname) => {
        let futureShows = [...state.futureShows];
        var objectToChangeIdx = futureShows.findIndex(show => show.name === showname);
        var objectChanged = { ...futureShows[objectToChangeIdx] };

        objectChanged.selectedpackage = packagetype;

        if (packagetype === 'Basic') {
            //empty three checkboxes
            objectChanged.weblink = '';
            objectChanged.noshowleads = '';
        }

        futureShows[objectToChangeIdx] = objectChanged;

        setState({
            ...state,
            futureShows
        });     
    };

    const handleBoothChange = (booth, showname) => {
        let target = booth.target;
        let name = target.name;
        let value = target.value;

        let futureShows = [...state.futureShows];
        var objectToChangeIdx = futureShows.findIndex(show => show.name === showname);
        var objectChanged = { ...futureShows[objectToChangeIdx] };

        objectChanged.booth = value;

        futureShows[objectToChangeIdx] = objectChanged;

        setState({
            ...state,
            futureShows
        });
    };

    function handleProcessJSSSalesOrder(worklistItem, action, comments, addSystemNote) {
        toast.success("Request Processed");

        console.log(worklistItem);
        console.log(action);
        console.log(comments);

        //this get's called as a prop from ApproveDenyOrRequestMoreInfoButton.js

        //let formFields = { ...state.formFields };

        ////if not a submission from the worklist dashboard for approval or denial
        //if (!props.worklistItem.assignedToUsername === uInfo.username) { return; }

        //if (!formFields.name || formFields.customerType === null || formFields.customerType === '' || formFields.groupCode === 'Select' || !formFields.territoryID) {
        //    toast.error("Mandatory fields missing!");
        //    return;
        //} else if (readyForApproval && !formFields.custID && action === 'Approved') {
        //    toast.error("Cust ID missing!");
        //    return;
        //}

        //setLoading(true);

        //let postdata = {
        //    worklistId: props.worklistItem.worklist_ID,
        //    action,
        //    epicoreCustomerContactNum: props.worklistItem.epicoreCustomerContactNum,
        //    epicoreCustomerNum: props.worklistItem.epicoreCustNum,
        //    comments: comments,
        //    customer: formFields,
        //    selectedEpicorCompany: uInfo.selectedEpicorCompany,
        //    addSystemNote,
        //    reasonForRequest: props.worklistItem.reasonForRequest
        //};

        
        //adminApproveDenyRequestMoreInfo(postdata)
        //    .then(response => {
        //        setLoading(false);
        //        toast.success("Request Processed")
        //        hidePane();
        //        props.getWorklistByUser();
        //    }).catch(error => {
        //        setLoading(false);
        //        toast.error("Problem Encountered During Request Processing");
        //    });

    }

    return (
        <Drawer
                closeIcon={<i className="fas fa-chevron-left float-left"></i>}
                title='Create Sales Order Entry'
                placement={'right'}
                onClose={hidePane}
                visible={state.showPane}
                key='createSalesOrder'
                width={'80%'}

                footer={
                    <div className="form-group col-12 padding-25-10">
                        {!props.selectedWorklistItem ? (
                            <>
                                <button id="btnSaveChanges" onClick={handleSubmitRealWorkflow} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>{state.submitButtonText}</button>
                                <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                            </>
                        ) : (
                            <>
                                {props.selectedWorklistItem?.assignedToUsername === 'Admin Review' && (
                                    <>
                                        //<button className="btn btn-submit btn-sm margin-left-15 float-left" onClick={() => { props.handleAssignWorklistItem(props.selectedRow, null); hidePane(); }}>I'll Take It</button>&nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-outline-primary btn-sm margin-left-15 float-right" onClick={hidePane}>Close</button>
                                    </>
                                )}

                                {props.selectedWorklistItem?.assignedToUsername === userInfo.userId && (
                                    <>
                                        <ApproveDenyOrRequestMoreInfoButton worklistItem={props.selectedWorklistItem} handleProcessJSSSalesOrder={handleProcessJSSSalesOrder} />
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-outline-primary btn-sm margin-left-15 float-right" onClick={hidePane}>Close</button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                }
            >
                <div className="slide-pane-body">
                <div className="row">
                    <div className="col-lg-6">
                        {state.dataLoaded &&
                            <div>
                                <Card className="d2pCard">
                                    <Card.Header><span>Upcoming Shows</span></Card.Header>
                                    <Card.Body>
                                        {state.futureShows &&
                                            state.futureShows.map((show, index) => (
                                                <div style={{ "paddingBottom": "10px" }} className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                                        <select style={{ "width": "110px" }} name="showname" value={show.salesstatus} onChange={(selectedOption) => handleSalesStatusChange(selectedOption, show.name)}>
                                                            <option value="" />
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                            <option value="Interested">Interested</option>
                                                            <option value="Waitlist" style={show.invoiced === 'No' ? { display: 'none' } : {}}>Waitlist</option>
                                                        </select>

                                                        <span style={{ "display": "inlineBlock", "paddingLeft": "20px" }}>{show.name}</span><span style={!props.selectedWorklistItem ? { display: 'none' } : {}}>&nbsp;&nbsp;-&nbsp;&nbsp;{show.invoiced}</span>&nbsp;&nbsp;
                                                        <span style={show.invoiced === 'No' || show.invoiced === 'Not Invoiced' ? { display: 'none' } : {}}><label>Booth:&nbsp;</label><input className="inline-text-box" type="text" name="booth" placeholder="" value={show.booth} onChange={(value) => handleBoothChange(value, show.name)} /></span>

                                                        <br />
                                                        <input type="checkbox" name={`leadretrieval${show.name}`} onChange={() => handleOptionChange('leadretrieval', show.name)} checked={show.leadretrieval === 'Yes'} /> Lead Retrieval&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                                                        <input type="radio" name={`pkg${show.name}`} onChange={() => handlePackageTypeChange('Basic', show.name)} checked={show.selectedpackage === 'Basic'} /> Basic Package&nbsp;&nbsp;&nbsp;
                                                        <input type="radio" name={`pkg${show.name}`} onChange={() => handlePackageTypeChange('Custom', show.name)} checked={show.selectedpackage === 'Custom'} /> Custom Package:&nbsp;&nbsp;

                                                        <input type="checkbox" name={`weblink${show.name}`} onChange={() => handleOptionChange('weblink', show.name)} checked={show.weblink === 'Yes'} disabled={show.selectedpackage === 'Basic' || show.selectedpackage === ''} /> Weblink&nbsp;&nbsp;
                                                        <input type="checkbox" name={`noshowleads${show.name}`} onChange={() => handleOptionChange('noshowleads', show.name)} checked={show.noshowleads === 'Yes'} disabled={show.selectedpackage === 'Basic' || show.selectedpackage === ''} /> No Show Leads&nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Card.Body>
                                </Card>
                            </div>
                        }
                    </div>
                    <div className="col-lg-6">
                        <Card className="d2pCard">
                            <Card.Header><span>Notes</span></Card.Header>
                            <Card.Body>
                                <textarea style={{ width: '100%', height: '200px' }} name="notes" placeholder="" value={state.searchForm.notes} onChange={handleInputChange} />
                            </Card.Body>
                        </Card>
                        
                    </div>
                </div>
            </div>
            </Drawer>
    );
}