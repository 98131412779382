import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import JoditEditor, { Jodit } from 'jodit-react';
import { getRepEmailTemplates, deleteRepEmailTmplate, addRepEmailTemplate, updateRepEmailTemplate } from '../../services/RepEmailTemplateService';
import { toast, confirm } from '@rickylandino/react-messages';
import { Empty, Spin, Alert } from 'antd';
import { activeUserInfo } from '../../services/UsersService';
import { Fragment } from 'react';

export default function WidgetRepEmailTemplateLibrary(props) {
    const { register, getValues, setValue, watch } = useForm();
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTemplateType, setSelectedTemplateType] = useState(null);
    const [templateDropdown, setTemplateDropdown] = useState([]);
    const [emailSignatures, setEmailSignatures] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [editorSignatures, setEditorSignatures] = useState({});
    const [editorMergeFields, setEditorMergeFields] = useState([]);

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        dataLoaded: false
    });

    useEffect(() => {
        setState({
            ...state,
            dataLoaded: false
        });

        getRepEmailTemplates(userInfo.userId, userInfo.jobShopDivision).then(data => {
            if (data) {
                setEmailTemplates(data);

                let templates = [];
                let signatures = [];

                for (var i = 0; i < data.length; i++) {
                    if (data[i].templateType === 'email') {
                        //if (data[i].shared === true) {
                        //    data[i].templateName += ' (shared)';
                        //}
                        templates.push(data[i]);
                    }
                    else {
                        signatures.push(data[i]);
                    }
                }

                setEmailTemplates(templates);
                setEmailSignatures(signatures);

                //load email signatures for editor dropdown
                let editorSignatures = {};
                signatures.filter(et => et.templateType === 'signature')
                    .forEach(et => {
                        editorSignatures[et.repEmailTemplateLibrary_ID] = et.templateName
                    });
                setEditorSignatures(editorSignatures);

                //load merge fields for editor dropdown
                let editorMergeFields = ["Contact Name", "Company Name"];
                setEditorMergeFields(editorMergeFields);

                setState({
                    ...state,
                    dataLoaded: true
                });

            } else {
                toast.error("Something went wrong retrieving rep email templates");
            }
        });
    }, [props]);


    useEffect(() => {
        if (state.dataLoaded === true) {
            setSelectedTemplateType('email');
            setTemplateDropdown(emailTemplates);

            setSelectedTemplate(null);
            setValue('templateName', '');
            setValue('templateSubject', '');
            setValue('sharedOrPivate', 'Private');
            setValue('saveButtonText', 'Add New Template');
            setContent('');
        }
    }, [state.dataLoaded]);

    useEffect(() => {
        setSelectedTemplate(null);
        setValue('templateName', '');
        setValue('templateSubject', '');
        setValue('sharedOrPivate', 'Private');
        setValue('saveButtonText', 'Add New Template');
        setContent('');

        if (selectedTemplateType === 'email') {
            setTemplateDropdown(emailTemplates);
        }
        else {
            setTemplateDropdown(emailSignatures);
        }
    }, [selectedTemplateType]);

    function selectTemplate(e) {
        e.preventDefault();
        let target = e.target;
        let name = target.name;
        let value = target.value;

        if (value !== '<Add New>') {
            let foundTemplate = templateDropdown.find(t => t.repEmailTemplateLibrary_ID === parseInt(value));

            if (foundTemplate) {
                setSelectedTemplate(foundTemplate);
                setValue('selectedTemplateText', foundTemplate.repEmailTemplateLibrary_ID);
                setValue('templateName', foundTemplate.templateName);
                setValue('templateSubject', foundTemplate.templateSubject);
                if (foundTemplate.shared === true) {
                    setValue('sharedOrPrivate', 'Shared');
                }
                else {
                    setValue('sharedOrPrivate', 'Private');
                }
                setValue('saveButtonText', 'Update Template');
                setContent(foundTemplate.stringContent);
            } else {
                setSelectedTemplate(null);
                setValue('selectedTemplateText', null);
                setValue('templateName', '');
                setValue('templateSubject', '');
                setValue('sharedOrPrivate', 'Private');
                setValue('saveButtonText', 'Add New Template');
                setContent('');
            }
        } else {
            setSelectedTemplate(null);
            setValue('selectedTemplateText', null);
            setValue('templateName', '');
            setValue('templateSubject', '');
            setValue('sharedOrPrivate', 'Private');
            setValue('saveButtonText', 'Add New Template');
            setContent('');
        }
    }

    function selectTemplateType(e) {
        e.preventDefault();
        let target = e.target;
        let name = target.name;
        let value = target.value;

        setValue('sharedOrPrivate', 'Private');
        setSelectedTemplateType(value);
    }

    function handleSubmit() {
        var mySharedOrPrivate;

        if (getValues().sharedOrPrivate === null) {
            toast.error("You must specify if the template is Private or Shared");
            return;
        }

        if (getValues().sharedOrPrivate === 'Private' || selectedTemplateType === 'Signature') {
            mySharedOrPrivate = false;
        }
        else {
            mySharedOrPrivate = true;
        }

        if (getValues().templateName) {
            if (selectedTemplate) {
                let postdata = {
                    repEmailTemplateLibrary_ID: selectedTemplate.repEmailTemplateLibrary_ID,
                    templateType: selectedTemplateType,
                    templateName: getValues().templateName,
                    templateSubject: getValues().templateSubject,
                    stringContent: content,
                    ownedBy: userInfo.userId,
                    division: userInfo.jobShopDivision,
                    shared: mySharedOrPrivate
                };

                updateRepEmailTemplate(postdata).then(data => {
                    if (data?.repEmailTemplateLibrary_ID) {
                        var newList;
                        if (selectedTemplateType === 'email') {
                            newList = [...emailTemplates];
                        }
                        else {
                            newList = [...emailSignatures];
                        }

                        let idx = newList.findIndex(item => item.repEmailTemplateLibrary_ID === parseInt(data.repEmailTemplateLibrary_ID));
                        if (idx >= 0) {
                            newList[idx] = data;
                            newList[idx].stringContent = content;
                            
                            if (selectedTemplateType === 'email') {
                                setEmailTemplates(newList);
                            }
                            else {
                                setEmailSignatures(newList);
                            }
                            setTemplateDropdown(newList);

                            toast.success("Your email template has been updated");
                        }
                    } else {
                        toast.error("Something went wrong");
                    }
                });

            } else {
                //add template
                let postdata = {
                    templateType: selectedTemplateType,
                    templateName: getValues().templateName,
                    templateSubject: getValues().templateSubject,
                    stringContent: content,
                    ownedBy: userInfo.userId,
                    division: userInfo.jobShopDivision,
                    shared: mySharedOrPrivate
                };

                addRepEmailTemplate(postdata).then(data => {
                    if (data?.repEmailTemplateLibrary_ID) {
                        setSelectedTemplate(data);

                        var newList;
                        if (selectedTemplateType === 'email') {
                            newList = [...emailTemplates];
                            data.stringContent = content;
                            newList.push(data);
                            setEmailTemplates(newList);
                        }
                        else {
                            newList = [...emailSignatures];
                            data.stringContent = content;
                            newList.push(data);
                            setEmailSignatures(newList);
                        }
                        setTemplateDropdown(newList);

                        toast.success("Your email template has been added");
                    } else {
                        toast.error("Something went wrong")
                    }
                });
            }
        } else {
            toast.error("Template Name is a required field");
        }
    }

    function handleDeleteRepEmailTemplate() {
        confirm({
            title: <span><i className='far fa-trash-alt text-danger icon-md pr-1'></i> &nbsp; You are about to permanently delete this email template.</span>,
            content: "Are you sure you would like to proceed? This action cannot be undone.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let postdata = selectedTemplate;

                console.log(postdata);

                deleteRepEmailTmplate(postdata).then(data => {
                    if (data) {
                        setValue('selectedTemplateText', '<Add New>');
                        setSelectedTemplate(null);
                        setValue('templateName', '');
                        setValue('templateSubject', '');
                        setValue('sharedOrPivate', 'Private');
                        setValue('saveButtonText', 'Add New Template');
                        setContent('');

                        var newList;
                        if (selectedTemplateType === 'email') {
                            newList = [...emailTemplates];
                            newList = newList.filter(t => t.repEmailTemplateLibrary_ID != selectedTemplate.repEmailTemplateLibrary_ID);
                            setEmailTemplates(newList);
                        }
                        else {
                            newList = [...emailSignatures];
                            newList = newList.filter(t => t.repEmailTemplateLibrary_ID != selectedTemplate.repEmailTemplateLibrary_ID);
                            setEmailSignatures(newList);
                        }
                        setTemplateDropdown(newList);

                        toast.success("Email Template has been deleted");
                    } else {
                        toast.error("Something went wrong");
                    }
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handlePrivacyIndicatorChange(e) {
        let target = e.target;
        /*let name = target.name;*/
        let value = target.value;

        setValue('sharedOrPrivate', value);

        setState({
            ...state,
        });
    }

    var joditButtons = [...Jodit.defaultOptions.buttons];

    if (selectedTemplateType !== 'signature') {
        joditButtons.push({
            name: 'Signatures',
            list: editorSignatures,
            exec: (ed, nodeOrFalse, control, origEvent, btn) => {
                console.log(control.control.name);

                let foundTemp = emailSignatures?.find(es => es.repEmailTemplateLibrary_ID === parseInt(control.control.name));
                if (foundTemp) {
                    ed.s.insertHTML("<div id=\"signature\"" + foundTemp.stringContent + "</div>");
                }
            }
        });
        joditButtons.push({
            name: 'Merge Fields',
            list: editorMergeFields,
            exec: (ed, nodeOrFalse, control, origEvent, btn) => {
                let foundField = editorMergeFields?.find(emf => emf === control.control.name);
                if (foundField) {
                    ed.s.insertHTML('[[' + control.control.name + ']]');
                }
            }
        });
    } else {
        let sigIdx = joditButtons.findIndex(jb => jb.name === 'Signatures');
        if (sigIdx >= 0) {
            joditButtons.splice(sigIdx, 1);
        }
    }

    function handleCopyTemplate() {
        setValue('selectedTemplateText', '<Add New>');
        setSelectedTemplate(null);
        setValue('templateName', '');
        setValue('saveButtonText', 'Add New Template');
    }

    return (
        <div className="row">
            <Spin spinning={!state.dataLoaded}>
                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                    <Alert
                        message="Loading Rep Templates"
                        description="Please stand by while we retrieve your email templates & signatures"
                        type="info"
                    />
                </div>
                {state.dataLoaded &&
                    <Fragment>
                        <div className="row">
                            <div className="form-group col-lg-3 col-12">
                                <label>Template Type</label>
                                <select className="ant-input" onChange={selectTemplateType}>
                                    <option value='email'>Email</option>
                                    <option value='signature'>Signature</option>
                                </select>
                            </div>

                            <div className="horizontal-campaigns-divider"></div>

                            <div className="form-group col-lg-5 col-12">
                                <label>Selected Template</label>
                                <select className="ant-input" onChange={selectTemplate} value={selectedTemplate?.repEmailTemplateLibrary_ID || 0}>
                                    <option value={null}>{'<Add New>'}</option>
                                    {templateDropdown.map((t, idx) => <option key={idx} value={t.repEmailTemplateLibrary_ID}>{t.templateName}</option>)}

                                </select>
                                {/*<select className="ant-input" onChange={selectTemplate} value={selectedTemplate?.repEmailTemplateLibrary_ID || null}>*/}
                                {/*    <option value={null}>{'<Add New>'}</option>*/}
                                {/*    {templateDropdown.map((t, idx) => <option key={idx} value={t.repEmailTemplateLibrary_ID}>{t.templateName}</option>)}*/}

                                {/*</select>*/}
                            </div>

                            <div className="form-group col-lg-8 col-12">
                                <label>Template Name <span className="text-danger">*</span></label>
                                <input className="ant-input" {...register('templateName')} />
                            </div>

                            <div className="form-group col-lg-8 col-12" style={selectedTemplateType === 'email' ? {} : { display: 'none' }}>
                                <label>Email Subject</label>
                                <input className="ant-input" {...register('templateSubject')} />
                            </div>

                            <div className="col col-12">
                                <JoditEditor
                                    ref={editor}
                                    value={content}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => { }}
                                    config={{
                                        height: "480",
                                        allowResizeY: true,
                                        uploader: {
                                            insertImageAsBase64URI: true
                                        },
                                        buttons: joditButtons,
                                        removeButtons: ['source', 'about', 'video', 'file', 'fullsize', 'preview', 'image'],
                                    }}
                                />
                            </div>

                            <div style={selectedTemplateType === 'email' ? {} : { display: 'none' }}>
                                <p></p>
                                <label className="form-label">Shared or Private:</label>&nbsp;&nbsp;
                                <input type="radio" name="privacyIndicator" value="Private" className="mt-1" {...register('sharedOrPrivate')} checked={getValues().sharedOrPrivate === "Private"} onChange={handlePrivacyIndicatorChange} />&nbsp;<label className="form-label">Private Template</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="privacyIndicator" value="Shared" className="mt-1" {...register('sharedOrPrivate')} checked={getValues().sharedOrPrivate === "Shared"} onChange={handlePrivacyIndicatorChange} />&nbsp;<label className="form-label">Shared Template</label>&nbsp;&nbsp;&nbsp;
                            </div>

                            <div className="col col-12 mt-3">
                                <button className="ant-btn ant-btn-primary" onClick={handleSubmit}>{getValues().saveButtonText}</button>&nbsp;&nbsp;&nbsp;
                                {getValues().saveButtonText === 'Update Template' &&
                                    <button className="ant-btn ant-btn-primary" onClick={handleCopyTemplate}>Copy Template</button>
                                }
                                {selectedTemplate?.repEmailTemplateLibrary_ID &&
                                    <div className="float-end">
                                        <button className="ant-btn ant-btn-danger float-right m-1" onClick={handleDeleteRepEmailTemplate}><i className='far fa-trash-alt'></i> &nbsp; Delete this Template</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                }
            </Spin>
            
        </div> 
    );
}