import Axios from '../config/axios';

export function addShowInterest(postdata) {
    return Axios.post(`/api/AddShowInterest`, postdata
    ).then(response =>
        response.data
    ).catch(error => error);
}

export function convertInterested(postdata) {
    return Axios.post(`/api/ConvertInterested`, postdata
    ).then(response =>
        response.data
    ).catch(error => error);
}
