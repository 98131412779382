import Axios from '../config/axios';
import { BehaviorSubject } from 'rxjs';
import { throwError } from '../components/Helpers/Toast';

export function getGroupedReports(userid, division) {
    return Axios.get('/api/GetGroupedReports',
        {
            params: {
                userid,
                division
            }
        }).then(response => response.data).catch(error => throwError());
}

export function downloadReportByName(postdata) {
    console.log(postdata);
    return Axios.post(`/api/DownloadReportByName`, postdata, {
        responseType: 'blob'
    }).then(response => response).catch(error => console.log(error));
}