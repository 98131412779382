import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Select, Empty, Spin, Alert, Table, Modal } from 'antd';
import Globals from '../../config/globals';
import DatePicker from 'react-datepicker';
import { TbUserCheck } from 'react-icons/tb';
import { GiJumpAcross } from 'react-icons/gi';
import CustomerContactDetailsSlider from '../Customers/CustomerContactDetailsSlider';
import SendEmailSlider from '../Email/SendEmailSlider';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Fragment } from 'react';

export default function AddJSSProspect(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        showPane: false,
        salesreps: [],
        qccerts: [],
        prospectsources: [],
        servicecategories: [],
        shows: [],
        states: [],
        dataLoaded: false,
        searchRunning: false,
        hasSearchBeenRunYet: false,
        currentPage: 1,
        currentPageSize: 10,
        showContactDetailsSlider: false,
        contactEditMode: 'Add',
        showSendEmailSlider: false,
        disableAltAddressFields: true
    });

    const [loadingDropdowns, setLoadingDropdowns] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [filteredContacts, setfilteredContacts] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [nameExceptionChecked, setNameExceptionChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setLoadingDropdowns(true);

        LoadSearchFilters();

        //var mySearchRunning;
        //if (location.state.selectedCustomerId) {
        //    mySearchRunning = true;
        //}
        //else {
        //    mySearchRunning = false;
        //}

        //setState({
        //    ...state,
        //    searchRunning: mySearchRunning
        //});

        //This option clears all the last search filter selections
        //ResetSearchCriteria();

        //This option would re-load the last search filter selections
        //Note: Still needs work when switching between JSS/JST profiles - probably need to clear out session storage since objects are different
        /*SetSearchCriteria();*/

    }, [props]);

    useEffect(() => {
        if (loadingDropdowns === false) {
            
        }
    }, [loadingDropdowns])

    function LoadSearchFilters() {
        let postdata = {};
        postdata.jobshopdivision = userInfo.jobShopDivision

        Axios.post(`/api/GetJSSSearchFilters`, postdata
        ).then(response => {
            let salesreps = response.data.salesreps;
            let qccerts = response.data.qccerts;
            let prospectsources = response.data.prospectsources;
            let states = response.data.states;
            let servicecategories = response.data.servicecategories;
            let shows = response.data.shows;

            setState({
                ...state,
                salesreps,
                qccerts,
                prospectsources,
                servicecategories,
                shows,
                states,
                showPane: true
            });

            setLoadingDropdowns(false);

        }).catch(error => {
            console.log(error);
        });
    }

    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    const columns = [
        {
            dataIndex: 'contact_id',
            title: 'contact_id',
            key: 'contact_id',
            hidden: true
        }, {
            dataIndex: 'jsS_name',
            title: 'Contact Name',
            key: 'jsS_name'
        }, {
            dataIndex: 'title',
            title: 'Title',
            key: 'title'
        }, {
            dataIndex: 'contact_type',
            title: 'Contact Type',
            key: 'contact_type'
        }, {
            title: 'Edit',
            key: 'action',
            width: 75,
            render: (record) => (
                <span className="hover" onClick={(e) => loadContactDetails(e, record)}><i id="contactDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }, {
            title: 'Delete',
            key: 'action',
            width: 75,
            render: (record) => (
                <i id="deleteContact" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDeleteContact(e, record)}></i>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleQCCertsChange(value) {
        setValue('selectedQCCerts', value);
        setState({ ...state });
    }

    function handleProspectSourcesChange(value) {
        setValue('selectedProspectSources', value);
        setState({ ...state });
    }

    function handleServiceCategoryChange(value) {
        setValue('selectedServiceCategories', value);
        setState({ ...state });
    }

    function handleStatesChange(value) {
        setValue('formFields.state', value);
        setState({ ...state });
    }

    function handleShowsOfInterestChange(value) {
        setValue('selectedShowsOfInterest', value);
        setState({ ...state });
    }

    const { Option } = Select;

    function updatePageState(page, pageSize) {
        setState({
            ...state,
            currentPage: page,
            currentPageSize: pageSize
        });
    }

    function handleNameExceptionChecked() {
        setNameExceptionChecked(!nameExceptionChecked);
    }

    function loadContactDetails(e, record) {

        setValue('formFields.contact', record);

        const selectedIdx = contacts.findIndex(con => con.contact_id === record.contact_id);

        setState({
            ...state,
            selectedItemId: record.contact_id,
            selectedIdx,
            contactEditMode: 'Edit',
            showContactDetailsSlider: true
        });
    }

    function handleAddNewContact() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            contactEditMode: 'Add',
            dataLoaded: true,
            showContactDetailsSlider: true
        });
    }

    function handleDeleteContact(e, record) {
        confirm({
            title: "You are about to permanently delete this Contact",
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                var myContacts = [...contacts]
                myContacts = myContacts.filter(contact => contact.contact_id != record.contact_id);

                setContacts(myContacts);

                toast.success('Contact deleted successfully');

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleSalesRepSelectorFieldChange(value) {
        setValue('formFields.jSS_sales_rep', value);

        setState({ ...state });
    }

    function screenDataOK() {
        let message = '';

        if (getValues().selectedServiceCategories && getValues().selectedServiceCategories.length > 5) {
            message += '&bull;&nbsp;Maximum of 5 Service Category selections are allowed</br>';
        }
        if (getValues().selectedQCCerts && getValues().selectedQCCerts.length > 4) {
            message += '&bull;&nbsp;Maximum of 4 QC Cert selections are allowed</br>';
        }
        if (getValues().selectedProspectSources && getValues().selectedProspectSources.length > 3) {
            message += '&bull;&nbsp;Maximum of 3 Prospect Source selections are allowed</br>';
        }

        //mandatory fields check
        if (getValues().formFields.name === '') {
            message += '&bull;&nbsp;A Company Name must be provided</br>';
        }
        if (getValues().formFields.street1 === '') {
            message += '&bull;&nbsp;A Street Address must be provided</br>';
        }
        if (getValues().formFields.city === '') {
            message += '&bull;&nbsp;A City must be provided</br>';
        }
        if (getValues().formFields.state === '' || getValues().formFields.state === undefined) {
            message += '&bull;&nbsp;A State must be provided</br>';
        }

        if (contacts === undefined || contacts.length === 0) {
            message += '&bull;&nbsp;At least one contact must be provided</br>';
        }

        if (getValues().selectedShowsOfInterest === undefined || getValues().selectedShowsOfInterest.length === 0) {
            message += '&bull;&nbsp;At least one show of interest must be selected</br>';
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    function handleSaveProspect() {
        if (screenDataOK() === false) {
            return;
        }

        //split out each service Category
        if (getValues().selectedServiceCategories) {
            for (var i = 0; i < getValues().selectedServiceCategories.length; i++) {
                setValue('formFields.jSS_Category' + (i + 1).toString(), getValues().selectedServiceCategories[i]);
            }
        }
        //split out each qc cert
        if (getValues().selectedQCCerts) {
            for (var i = 0; i < getValues().selectedQCCerts.length; i++) {
                setValue('formFields.jSS_qc' + (i + 1).toString(), getValues().selectedQCCerts[i]);
            }
        }
        //split out each prospect source
        if (getValues().selectedProspectSources) {
            for (var i = 0; i < getValues().selectedProspectSources.length; i++) {
                if (i === 0) {
                    setValue('formFields.jSS_source', getValues().selectedProspectSources[i]);
                }
                else {
                    setValue('formFields.jSS_source' + (i + 1).toString(), getValues().selectedProspectSources[i]);
                }
            }
        }
        //set exh_prospect flag to true
        setValue('formFields.exh_Prospect', 1);

        let postdata = {};
        postdata.customer = getValues().formFields;
        postdata.customerContacts = contacts;
        postdata.showsofinterest = getValues().selectedShowsOfInterest;

        Axios.post('/api/InsertNewJSSProspect', postdata
        ).then(response => {
            var responseStr = response.data.toString();

            console.log(responseStr);

            if (responseStr.includes('Prospect Not Added')) {
                toast.error(responseStr);
            }
            else {
                toast.success('New prospect added successfully');
                navigate("/customerview", { state: { selectedCustomerId: responseStr, callingComponent: 'AddProspect' } });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    //function contactScreenDataOK() {
    //    let message = '';

    //    //mandatory fields check
    //    if (getValues().formFields.contact.jsS_name === '') {
    //        message += '&bull;&nbsp;A Contact Name must be provided</br>';
    //    }
    //    if (getValues().formFields.contact.contact_type === '') {
    //        message += '&bull;&nbsp;A Contact Type must be provided</br>';
    //    }

    //    //for main, logistics, total package contacts - we only want one
    //    if (getValues().formFields.contact.contact_type === 'Main' || getValues().formFields.contact.contact_type === 'Logistics' || getValues().formFields.contact.contact_type === 'Total Package') {
    //        for (var i = 0; i < contacts.length; i++) {
    //            if ((contacts[i].contact_type === getValues().formFields.contact.contact_type) && (contacts[i].contact_id !== state.selectedItemId)) {
    //                message += '&bull;&nbsp;Contact type \'' + getValues().formFields.contact.contact_type + '\' already exists for this Exhibitor</br>';
    //            }
    //        }
    //    }

    //    if (message != '') {
    //        message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
    //        toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

    //        return false;
    //    }
    //    else {
    //        return true;
    //    }
    //}

    function handleSaveContact(contact) {
        //if (contactScreenDataOK() === false) {
        //    return;
        //}

        setValue('formFields.contact', contact);

        if (state.contactEditMode === 'Add') {
            var myNextFugaziContactID = -1;

            //took this approach since we are adding the contact into the list but not into the DB yet until the 'Save' button is clicked. 
            //Setting contact_id to negative value will indicate to the API it needs to be added
            for (var i = 0; i < contacts.length; i++) {
                if (contacts[i].contact_id == myNextFugaziContactID) {
                    myNextFugaziContactID -= 1;
                }
            }
            setValue('formFields.contact.contact_id', myNextFugaziContactID);

            //var myMaxContactID = 0;

            //for (var i = 0; i < contacts.length; i++) {
            //    if (contacts[i].contact_id > myMaxContactID) {
            //        myMaxContactID = contacts[i].contact_id;
            //    }
            //}

            ////took this approach since there is no contact_id in the list because they are not actually coming from a DB model 
            ////so we had to create the 'field'
            //setValue('formFields.contact.contact_id', (myMaxContactID + 1));

            setContacts([
                ...contacts,
                { ...getValues().formFields.contact }
            ]);

            //setValue('formFields.contact', {});

            toast.success('New Contact added successfully');

            setState({
                ...state,
                showContactDetailsSlider: false
            });

        }
        else {
            var myContacts = [...contacts];
            myContacts = myContacts.map(c => c.contact_id !== state.selectedItemId ? c : contact);

            setContacts(myContacts);

            //setValue('formFields.contact', {});

            toast.success('Contact updated successfully');

            setState({
                ...state,
                showContactDetailsSlider: false
            });
        }
    }

    function closeSlider() {
        setValue('formFields.contact', {});

        setState({
            ...state,
            showContactDetailsSlider: false
        });

        //if (isDirty.current) {
        //    confirm({
        //        title: "You are about to close this note and lose any changes you've made.",
        //        content: "Are you sure you would like continue?",
        //        buttons: ["Yes", "No"],
        //    }, (buttonPressed) => {
        //        if (buttonPressed === 'Yes') {
        //            hidePane();

        //            return 0;
        //        } else {
        //            return 0;
        //        }
        //    });
        //} else {
        //    hidePane();
        //}
    }

    function handleReceiveEmailedInvoicesChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        setValue('formFields.contact.receiveEmailedInvoices', value);

        setState({
            ...state,
        });
    }

    function handleAltMailingAddressChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        setValue('formFields.contact.use_alt_address', value);

        setState({
            ...state,
            disableAltAddressFields: !value
        });
    }

    function showModal() {
        setValue('formFields.newProspectSource', '');
        setIsModalOpen(true);
    };

    function handleModalOk() {
        if (getValues().formFields.newProspectSource !== '') {
            var myProspectSources;
            if (getValues().selectedProspectSources !== undefined) {
                myProspectSources = [...getValues().selectedProspectSources];
            }
            else {
                myProspectSources = [];
            }

            myProspectSources.push(getValues().formFields.newProspectSource);

            console.log(myProspectSources);

            setValue('selectedProspectSources', myProspectSources);
            setState({ ...state });

            //add the new prospect source to the DB on the fly
            let postdata = {};
            postdata.source = getValues().formFields.newProspectSource;
            postdata.division = 'JSS';
            postdata.archived = false;

            Axios.post('/api/InsertProspectSource', postdata
            ).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            toast.error('No Source was specified');
        }

        setIsModalOpen(false);
    };

    function handleModalCancel() {
        setIsModalOpen(false);
    };

    function handleAddProspectSource() {
        console.log(getValues().selectedProspectSources);

        setValue('formFields.newProspectSource', '');
        setIsModalOpen(true);
    }

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    function updateTableDisplay() {

    }

    function reloadContactsFromDB() {

    }

    return (
        <>
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Add New JSS Prospect'
            onRequestClose={hidePane}
            width={'90%'}
        >
            
            <div className="row">
                <div className="form-group col-lg-12 col-12">
                    <h3 className="frame-heading">
                        Add New JSS Prospect
                    </h3>
                </div>

                <div className="form-group col-lg-3 col-12">
                    <label className="form-label">Company</label>
                    <input type="text" name="name" {...register("formFields.name")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-1 col-12">
                    <label className="form-label">Total Employees</label>
                    <input type="text" name="totalemployees" {...register("formFields.totalemployees")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-1 col-12">
                    <label className="form-label">Start Year</label>
                    <input type="text" name="startyear" {...register("formFields.startyear")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-1 col-12">
                    <label className="form-label">Square Feet</label>
                    <input type="text" name="squarefeet" {...register("formFields.squarefeet")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2 col-12">
                    <label className="form-label">Sales Rep</label>
                    <Select id="ddSalesRep"
                        value={getValues().formFields?.jSS_sales_rep}
                        className="form-control-custom"
                        mode="single"
                        placeholder="Select Sales Rep"
                        onChange={(value) => handleSalesRepSelectorFieldChange(value)}
                        className="form-control-custom w-100"
                        bordered={true}
                    >
                        {state.salesreps.map((rep) => <Option key={rep.name} value={rep.name}>{rep.name}</Option>)}
                    </Select>
                </div>
                <div className="form-group col-lg-2 col-12">
                    <input className="ms-5" type="checkbox" name="NameException" onClick={handleNameExceptionChecked} checked={nameExceptionChecked} />&nbsp;Name Exception
                </div>
                <div className="form-group col-lg-2 col-12"></div>

                <div className="form-group col-lg-2 col-12">
                    <label className="form-label">Street 1</label>
                    <input type="text" {...register("formFields.street1")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2 col-12">
                    <label className="form-label">Street 2</label>
                    <input type="text" {...register("formFields.street2")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2 col-12">
                    <label className="form-label">City</label>
                    <input type="text" {...register("formFields.city")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-1 col-12">
                    <label className="form-label">State</label>
                    <Select id="ddProspectStates"
                        value={getValues().formFields?.states}
                        className="form-control-custom"
                        mode="single"
                        placeholder="Select State"
                        onChange={handleStatesChange}
                        className="form-control-custom w-100"
                        bordered={false}
                    >
                        {state.states.map((s) => <Option key={s.fullName} value={s.state}>{s.state}</Option>)}
                    </Select>
                </div>
                <div className="form-group col-lg-1 col-12">
                    <label className="form-label">Zip</label>
                    <input type="text" {...register("formFields.zip")} className="form-control-custom" />
                </div>
                {/*<div className="form-group col-lg-1 col-12">*/}
                {/*    <label className="form-label">Country</label>*/}
                {/*    <input type="text" {...register("formFields.country")} className="form-control-custom" />*/}
                {/*</div>*/}
                <div className="form-group col-lg-4 col-12"></div>

                <div className="form-group col-lg-2 col-12">
                    <label className="form-label">Phone</label>
                    <input type="text" {...register("formFields.telephone")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2 col-12">
                    <label className="form-label">Ext.</label>
                    <input type="text" {...register("formFields.fax")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-2 col-12">
                    <label className="form-label">Fax</label>
                    <input type="text" {...register("formFields.fax")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-3 col-12">
                    <label className="form-label">Web Site</label>
                    <input type="text" {...register("formFields.domain")} className="form-control-custom" />
                </div>
                <div className="form-group col-lg-3 col-12"></div>

                <div className="form-group col-lg-12 col-12">
                    &nbsp;
                </div>

                <div className="form-group col-lg-5 col-12">
                    <div className="rcorners p-2">
                        <h3 className="frame-heading">
                            Contacts
                        </h3>

                        <div className="col-sm-12 col-md-12 col-lg-12">
                            {contacts.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                    <span>
                                        No Contacts Added Yet For This Prospect
                                    </span>
                                } />
                                :
                                <Table className="custom-ant-selection"
                                    rowKey={item => item.contact_id}
                                    rowSelection={rowSelection}
                                    hideSelectionColumn={true}
                                    bordered
                                    size="small"
                                    dataSource={contacts}
                                    columns={columns}
                                    pagination={{
                                        defaultPageSize: 10,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '25', '50', '100'],
                                        showTotal: (total, range) => (
                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                Showing {range[0]}-{range[1]} of {total}
                                            </span>
                                        )
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: () => {
                                                let selRows = [record.contact_id];
                                                setSelectedRowKeys([...selRows]);
                                            },
                                            onDoubleClick: (e) => {
                                                loadContactDetails(e, record);
                                            }
                                        }
                                    }}
                                />
                            }
                        </div>

                        <div className="row">
                            <div className="form-group col-8 mt-3">
                                <span style={{ "color": "red" }}>Contact Changes Will Be Applied When Prospect Is Saved</span>
                            </div>
                            <div className="form-group col-4 text-end mt-3">
                                <button className="btn btn-submit" onClick={handleAddNewContact}>Add New Contact</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group col-lg-1 col-12"></div>
                <div className="form-group col-lg-5 col-12">
                    <div className="rcorners p-2">
                        <h3 className="frame-heading">
                            Prospect Details
                        </h3>
                        <div className="form-group col-12">
                            <label className="form-label">Service Categories</label>
                            <Select id="ddProspectSources"
                                value={getValues().selectedServiceCategories}
                                className="form-control-custom"
                                mode="multiple"
                                placeholder="Select up to 5 service categories"
                                onChange={handleServiceCategoryChange}
                                className="form-control-custom w-100"
                                bordered={false}
                            >
                                {state.servicecategories.map((scat) => <Option key={scat.category} value={scat.category}>{scat.categoryName}</Option>)}
                            </Select>
                        </div>
                        <div className="form-group col-12">
                            <label className="form-label">QC Certs</label>
                            <Select id="ddQCCerts"
                                value={getValues().selectedQCCerts}
                                className="form-control-custom"
                                mode="multiple"
                                placeholder="Select up to 4 QC Certs"
                                onChange={handleQCCertsChange}
                                className="form-control-custom w-100"
                                bordered={false}
                            >
                                {state.qccerts.map((cert) => <Option key={cert.qcname} value={cert.qcname}>{cert.qcname}</Option>)}
                            </Select>
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Prospect Sources</label>&nbsp;&nbsp;&nbsp;<button type="button" class="form-label btn btn-link btn-sm" onClick={() => handleAddProspectSource()}>Add Source (if not in list below)</button>
                            <Select id="ddProspectSources"
                                value={getValues().selectedProspectSources}
                                className="form-control-custom"
                                mode="multiple"
                                placeholder="Select up to 3 Sources"
                                onChange={handleProspectSourcesChange}
                                className="form-control-custom w-100"
                                bordered={false}
                            >
                                {state.prospectsources.map((src) => <Option key={src.source} value={src.source}>{src.source}</Option>)}
                            </Select>
                        </div>

                        <div className="form-group col-12">
                            <label className="form-label">Shows of Interest</label>
                            <Select id="ddSShows"
                                value={getValues().selectedShowsOfInterest}
                                className="form-control-custom"
                                mode="multiple"
                                placeholder="Select one or more Shows"
                                onChange={handleShowsOfInterestChange}
                                className="form-control-custom w-100"
                                bordered={false}
                            >
                                {state.shows.map((show) => <Option key={show.showcode} value={show.showcode}>{show.name}</Option>)}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="form-group col-lg-1 col-12"></div>


                <div className="form-group col-lg-12 col-12">
                    <Button className="btn btn-submit" onClick={() => handleSaveProspect()} >Save Prospect</Button>
                    </div>
                </div>
            </SlidingPane>

            {state.showContactDetailsSlider &&
                <CustomerContactDetailsSlider showPane={state.showContactDetailsSlider} mode={state.contactEditMode} callingComponent='AddJSSProspect' contacts={contacts} selectedIdx={state.selectedIdx} selectedItemId={state.selectedItemId}
                    handleSaveContact={handleSaveContact} selectedCustomerId={props.selectedCustomerId} hidePane={() => setState({ ...state, showContactDetailsSlider: false })} />
            }

            <Modal title="Add New Prospect Source" visible={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel}>
                Enter New Source<input type="text" name="psource" {...register("formFields.newProspectSource")} className="form-control-custom" />
            </Modal>

        </>
    );
}