import React, { useEffect, useState } from 'react';
import MyActiveTasks from '../Tasks/MyActiveTasks';
import SendEmailTest from '../Tasks/SendEmailTest';
import { Divider, Table, Empty, Popover, Tabs } from 'antd';
import Moment from 'moment';
import { toast, confirm } from '@rickylandino/react-messages';
import {
    getWorklistByUser,
    worklistState,
    adminReviewQueueState,
    assignWorklistItem,
    processCustomerChange,
    provideMoreInfo,
    insertChangeRequestsWithWorklistItem,
    insertNewWorklistItem,
    processContactChange,
    insertDummyWorklistItem,
} from '../../services/WorklistService';
import { changeRequestTableColumns, PopoverTableDisplay } from '../Helpers/PopoverTableDisplay';
import { ApproveDenyOrRequestMoreInfoButton } from './Buttons/ApproveDenyOrRequestMoreInfoButton';
import { CommentsOnlyButton } from './Buttons/CommentsOnlyButton';
import { useForm, Controller } from 'react-hook-form';
import WorklistDialogueView from './WorklistDialogueView';
import Axios from '../../config/axios';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useRecoilState, useRecoilValue } from 'recoil';
import JSS_SalesOrderWFSlider from '../Workflow/JSS_SalesOrderWFSlider';
import JSS_ExhibitorChangeWFSlider from '../Workflow/JSS_ExhibitorChangeWFSlider';

export default function DashboardWF(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const [state, setState] = useState({
        
    });

    const { register, getValues, setValue, control, watch } = useForm();

    var urlend = window.api.slice(-1) === '/' ? 'hubs/worklist' : '/hubs/worklist';
    const url = window.api + urlend;

    const connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.Error)
        .withUrl(url)
        .withAutomaticReconnect()
        .build();

    const [worklist, setWorklist] = useRecoilState(worklistState);

    //const [worklist, setWorklistState] = useRecoilState(worklistState);

    const [filteredWorklist, setFilteredWorklist] = useState([]);
    const [userRequestHistory, setUserRequestHistory] = useState([]);
    const [showExhibitorChangeRequestSlider, setShowExhibitorChangeRequestSlider] = useState(false);
    const [selectedWorklistItem, setSelectedWorklistItem] = useState(null);
    const [selectedRow, setSelectedRow] = useState({});

    const [showSalesOrderWFSlider, setShowSalesOrderWFSlider] = useState(false);
    const [showExhibitorChangeWFSlider, setShowExhibitorChangeWFSlider] = useState(false);

    const filterCriteria = watch("filterFields", props.drivers);

    useEffect(() => {

        getLocalWorklist();

        connection.start()
            .then(result => {
                connection.on('UpdateWorklist', worklistList => {
                    var filteredUserWorklist =
                        worklistList.userWorklist?.filter(wl => wl.worklistItem.assignedToUsername === userInfo.userId);

                    worklistList.userWorklist = filteredUserWorklist;

                    setFilteredWorklist(filteredUserWorklist);
                });

                connection.on('UpdateUserRequestHistory', requestHistory => {
                    if (requestHistory?.username === userInfo.userId) {
                        setUserRequestHistory(requestHistory.requestHistory);
                    }
                    //setWorklist(requestHistory);
                });
            })
            .catch(e => {
                console.log("SignalR Error!");
                console.log('Connection failed: ', e);
            });
    }, [props]);

    useEffect(() => {
        setFilteredWorklist(worklist.userWorklist?.filter(wl => !wl.worklistItem?.disabled));
        setUserRequestHistory(worklist.userRequestHistory);
    }, [worklist]);

    function getLocalWorklist() {
        getWorklistByUser().then(data => {
            setWorklist(data);
        });
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                filterWorklist();
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, worklist]);

    function filterWorklist() {
        let fwl = [...worklist?.userWorklist];
        if (!filterCriteria.showDisabled) {
            fwl = fwl.filter(wl => !wl.worklistItem?.disabled);
        }

        setFilteredWorklist(fwl);
    }

    function worklistDialogueFormatter(cell, row, idx) {
        if (cell) {
            return (
                <Popover
                    content={
                        <WorklistDialogueView worklistParentId={cell} />
                    }
                    trigger="click"
                >
                    <i style={{ fontSize: '2rem' }} className="far fa-comments hover"></i>
                </Popover>
            );
        }
    }

    //function processSalesOrder(row) {
    //    console.log(row);
    //    setSelectedRow(row);
    //    setSelectedWorklistItem(row.worklistItem);
    //    setShowSalesOrderWFSlider(true);

    //    setState({
    //        ...state
    //    })
    //}

    function buttonFormatter(cell, row, idx) {
        //console.log('row', row);
        let itemDetail = row.worklistItem.itemDetail;

        if (itemDetail === 'Process JSS Sales Order') {
            return (
                <button className="btn btn-submit btn-sm" onClick={() => { setSelectedRow(row); setSelectedWorklistItem(row.worklistItem); setShowSalesOrderWFSlider(true); }}>Process Request</button>
            );
        }
        if (itemDetail === 'Process JSS Exhibitor Change Request') {
            return (
                <button className="btn btn-submit btn-sm" onClick={() => { setSelectedRow(row); setSelectedWorklistItem(row.worklistItem); setShowExhibitorChangeWFSlider(true); }}>Process Request</button>
            );
        }
    }

    const worklistColumns = [
        {
            title: 'Item Detail',
            dataIndex: ['worklistItem', 'itemDetail'],
            key: 'itemDetail'
        },
        {
            title: 'Date Created',
            dataIndex: ['worklistItem', 'itemCreationDate'],
            key: 'itemCreationDate',
            render: cell => Moment(new Date(cell)).format('L')
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName'
        },
        {
            title: 'Requestor',
            dataIndex: 'requestor',
            key: 'requestor'
        },
        //{
        //    title: 'Additional Details',
        //    dataIndex: 'changeRequests',
        //    key: 'changeRequests',
        //    render: (cell, row, idx) => <button className="btn btn-sm" onClick={() => { setSelectedRow(row); setSelectedWorklistItem(row.worklistItem); setShowContactDetailsSlider(true); }}>View Request Details</button>
        //},
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: buttonFormatter
            /*render: (cell, row, idx) => <button className="btn btn-submit btn-sm" onClick={() => { setSelectedRow(row); setSelectedWorklistItem(row.worklistItem); setShowContactDetailsSlider(true); }}>Process Request</button>*/
        },
        {
            title: 'Dialogue',
            dataIndex: ['worklistItem', 'worklistParent_ID'],
            key: 'worklistParent_ID',
            render: worklistDialogueFormatter
        }
    ];

    const historyColumns = [
        {
            title: 'Item Detail',
            dataIndex: ['worklistItem', 'itemDetail'],
            key: 'itemDetail',
            //filters: Array.from([...new Set(worklist?.userRequestHistory?.map(item => item.worklistItem.itemDetail))]).map(item => ({ text: item, value: item })),
            //onFilter: (value, row) => row.worklistItem?.itemDetail?.indexOf(value) === 0,
            sorter: (a, b) => a.worklistItem?.itemDetail?.localeCompare(b.worklistItem?.itemDetail)
        },
        {
            title: 'Date Created',
            dataIndex: ['worklistItem', 'itemCreationDate'],
            key: 'itemCreationDate',
            render: cell => Moment(new Date(cell)).format('L'),
            sorter: (a, b) => a.worklistItem?.itemCreationDate?.localeCompare(b.worklistItem?.itemCreationDate)
        },
        {
            title: 'Customer Name',
            dataIndex: ['worklistItem', 'customerName'],
            key: 'customerName',
            //filters: Array.from([...new Set(worklist?.userRequestHistory?.map(item => item.worklistItem.epicoreCustomerName))]).map(item => ({ text: item, value: item })),
            //onFilter: (value, row) => row.worklistItem?.epicoreCustomerName?.indexOf(value) === 0,
            sorter: (a, b) => a.worklistItem?.epicoreCustomerName?.localeCompare(b.worklistItem?.epicoreCustomerName)
        },
        {
            title: 'Assigned To',
            dataIndex: ['worklistItem', 'assignedToUsername'],
            key: 'assignedToUsername',
            //filters: Array.from([...new Set(worklist?.userRequestHistory?.map(item => item.worklistItem.assignedToUsername))]).map(item => ({ text: item, value: item })),
            //onFilter: (value, row) => row.worklistItem?.assignedToUsername?.indexOf(value) === 0,
            sorter: (a, b) => a.worklistItem?.assignedToUsername?.localeCompare(b.worklistItem?.assignedToUsername)
        },
        {
            title: 'Additional Details',
            dataIndex: 'changeRequests',
            key: 'changeRequests',
            render: (cell, row, idx) => <button className="btn btn-submit btn-sm" onClick={() => { setSelectedWorklistItem(row.worklistItem); setShowExhibitorChangeRequestSlider(true) }}>View Request Details</button>
        },
        {
            title: 'Status',
            dataIndex: 'displayStatus',
            key: 'displayStatus',
            render: cell => <em className={cell?.includes('Denied') ? 'text-danger' : cell?.includes('Approved') ? 'text-success' : undefined}>{cell}</em>,
            sorter: (a, b) => a.displayStatus.localeCompare(b.displayStatus)
        },
        {
            title: 'Comments',
            dataIndex: ['worklistItem', 'completionComments'],
            key: 'completionComments'
        }
    ];

    function handleProvideMoreInfoContact() {

    }

    function handleProcessCustomerChange() {

    }

    function handleProvideMoreInfo() {

    }

    function handleProcessContactChange() {

    }

    function myInsertDummyWorklistItem() {

        let postdata = {
            itemDetail: 'Process Exhibitor Change Request',
            originatorUsername: userInfo.userId,
            company_Id: 3547,
            assignedToUsername: 'dlandino'
        }

        insertDummyWorklistItem(postdata).then(data => {
            if (data) {
                toast.success("Change Request has been made");
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error =>
            toast.error("Something went wrong"));
    }

    const returnQueueColumns = (type) => {
        var retval = [
            {
                title: 'Item Detail',
                dataIndex: ['worklistItem', 'itemDetail'],
                key: 'itemDetail',
                //filters: Array.from([...new Set(worklist[type]?.map(item => item.worklistItem.itemDetail))]).map(item => ({ text: item, value: item })),
                //onFilter: (value, row) => row.worklistItem?.itemDetail === value,
                sorter: (a, b) => a.worklistItem?.itemDetail?.localeCompare(b.worklistItem?.itemDetail)
            },
            {
                title: 'Date Created',
                dataIndex: ['worklistItem', 'itemCreationDate'],
                key: 'itemCreationDate',
                render: cell => Moment(new Date(cell)).format('L')
            },
            {
                title: 'Customer Name',
                dataIndex: 'customerName',
                key: 'customerName',
                //filters: Array.from([...new Set(worklist[type]?.map(item => item.worklistItem.customerName))]).map(item => ({ text: item, value: item })),
                //onFilter: (value, row) => row.worklistItem?.customerName === value,
                sorter: (a, b) => a.worklistItem?.customerName?.localeCompare(b.worklistItem?.customerName)
            },
            {
                title: 'Requestor',
                dataIndex: 'requestor',
                key: 'requestor',
                //filters: Array.from([...new Set(worklist[type]?.map(item => item.worklistItem.assignedToUsername))]).map(item => ({ text: item, value: item })),
                //onFilter: (value, row) => row.worklistItem?.assignedToUsername === value,
                sorter: (a, b) => a.worklistItem?.assignedToUsername?.localeCompare(b.worklistItem?.assignedToUsername)
            },
            //{
            //    title: 'Additional Details',
            //    dataIndex: 'changeRequests',
            //    key: 'changeRequests',
            //    render: (cell, row, idx) => <button className="btn btn-sm" onClick={() => handleViewRequestDetails(row, idx)}>View Request Details</button>
            //},
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (cell, row, idx) => (row.worklistItem.assignedToUsername === type ? <button className="btn btn-submit btn-sm" onClick={() => handleAssignWorklistItem(row, idx)}>I'll Take It</button> : <em>With {row.worklistItem.assignedToUsername}</em>)
            },
            {
                title: 'Dialogue',
                dataIndex: ['worklistItem', 'worklistParent_ID'],
                key: 'worklistParent_ID',
                render: worklistDialogueFormatter
            }
        ];

        return retval;
    };

    function handleAssignWorklistItem(row, idx) {

        let worklistItem = { ...row.worklistItem };
        worklistItem.assignedToUsername = userInfo.userId;

        assignWorklistItem(worklistItem).then(data => {
            setWorklist(data);
            toast.success("Worklist Item has been assigned to you");
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }

    return (
        <>
            <Tabs>
                <Tabs.TabPane tab="My Worklist" key="myWorklist">
                    <div>
                        <h5>My Worklist</h5>
{/*                        <Divider></Divider>*/}
                    </div>

                    {/*<div className="row">*/}
                    {/*    <div className="col"><button className="btn btn-submit btn-sm" onClick={myInsertDummyWorklistItem}>Submit Dummy Change Request</button></div>*/}
                    {/*</div>*/}

                    {filteredWorklist?.length > 0 &&
                        <>
                            <Table dataSource={filteredWorklist} columns={worklistColumns} size="small" />
                        </>
                    }

                    {(!filteredWorklist?.length > 0) &&
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                            <span>
                                No pending worklist items
                            </span>
                        } />
                    }

                    {(userInfo.userQueueAccess?.includes('JSS Admin Review') && worklist['JSS Admin Review']?.length > 0) &&
                        <div>
                            <h5>Administrative Review Queue</h5>
                            <Divider></Divider>
                            <Table dataSource={worklist['JSS Admin Review']} columns={returnQueueColumns('JSS Admin Review')} size="small" />
                        </div>
                    }
                </Tabs.TabPane>
                {/*<Tabs.TabPane tab="My Request History" key="requestHistory">*/}
                {/*    <div>*/}
                {/*        <h5>Worklist History</h5>*/}
                {/*    */}{/*    <Divider></Divider>*/}
                {/*    </div>*/}
                {/*    {userRequestHistory?.length > 0 ?*/}
                {/*        <Table dataSource={userRequestHistory} columns={historyColumns} size="small" />*/}
                {/*        :*/}
                {/*        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={*/}
                {/*            <span>*/}
                {/*                No historical worklist items*/}
                {/*            </span>*/}
                {/*        } />*/}
                {/*    }*/}
                {/*</Tabs.TabPane>*/}
                <Tabs.TabPane tab="My Tasks" key="myTasks">
                    <div>
                        <h5>My Active {userInfo.jobShopDivision} Tasks</h5>
                    {/*    <Divider></Divider>*/}
                    </div>

                    <MyActiveTasks />
                </Tabs.TabPane>
                {/*<Tabs.TabPane tab="Email Test" key="emailTest">*/}
                {/*    <div>*/}
                {/*        <h5>Email Tester</h5>*/}
                {/*        */}{/*    <Divider></Divider>*/}
                {/*        {(userInfo.userId === 'dlandino' || userInfo.userId === 'blebo') &&*/}
                {/*            <SendEmailTest />*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</Tabs.TabPane>*/}
            </Tabs>

            {showSalesOrderWFSlider &&
                <JSS_SalesOrderWFSlider showPane={showSalesOrderWFSlider} selectedWorklistItem={selectedWorklistItem} hidePane={() => setShowSalesOrderWFSlider(false)} />
            }

            {showExhibitorChangeWFSlider &&
                <JSS_ExhibitorChangeWFSlider showPane={showExhibitorChangeWFSlider} selectedWorklistItem={selectedWorklistItem} hidePane={() => setShowExhibitorChangeWFSlider(false)} />
            }
        </>
    );
}