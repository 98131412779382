import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import { Tooltip, Popover, Select, Checkbox, List, message } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import SimpleSendEmailSlider from '../Email/SimpleSendEmailSlider';

export default function ShowDetailSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        mode: 'Add',
        usersListData: [],
        selectedCustomerId: props.selectedCustomerId,
        selectedShowCode: props.selectedShowCode,
        selectedShowName: props.selectedShowName,
        showdetail: {},
        boothpricing: {},
        boothStr: '',
        boothCostsStr: '',
        boothMoveStr: 'No pending booth moves',
        selectedServiceCodes: [],
        showSimpleSendEmailSlider: false,
        emailInformation: {}
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [hotelsBookedChecked, setHotelsBookedChecked] = useState([]);

    useEffect(() => {
        LoadShowDetails();

    }, [props]);

    function LoadShowDetails() {

        const key = "loading";
        message.loading({
            content: 'Loading show information...',
            key,
            duration: 0,
        });

        let postdata = {};
        postdata.exhibitorid = props.selectedCustomerId;
        postdata.showcode = props.selectedShowCode;
        postdata.division = userInfo.jobShopDivision;

        Axios.post(`/api/GetShowDetails`, postdata
        ).then(async response => {
            let showdetail = response.data.showdetail;
            let boothpricing = response.data.boothpricing;
            let servicecodes = response.data.servicecodes;
            let contacts = response.data.contacts;
            let futureshows = response.data.futureshows;

            console.log(futureshows);

            setValue('formFields.showdetail', showdetail);
            setValue('formFields.contacts', contacts);
            setValue('formFields.futureshows', futureshows);

            //Build Service Categories list with attendee counts
            var mySelectedServiceCodes = [];

            if (showdetail.prereglistcategories !== null) {
                for (var i = 0, l = servicecodes.length; i < l; i++) {
                    servicecodes[i].description += ' (' + servicecodes[i].attendeecount.toString() + ' Attendees)';
                    if (showdetail.prereglistcategories.includes("|" + servicecodes[i].code + "|")) {
                        mySelectedServiceCodes.push(servicecodes[i].code)
                    }
                }
            }
            setValue('formFields.servicecodes', servicecodes);

            //Check any contacts that booked hotel for the selected showcode
            var arrHotels = [];
            for (var i = 0, l = contacts.length; i < l; i++) {
                if (contacts[i].hotelsBooked !== null && contacts[i].hotelsBooked.includes(showdetail.showcode.toString())) {
                    arrHotels.push(contacts[i].contact_id)
                }
                
            }
            setHotelsBookedChecked(arrHotels);

            //build booths string
            let myBooths = '';
            if (showdetail.booth1 !== null && showdetail.booth1 !== '') { myBooths += showdetail.booth1 + ', '; }
            if (showdetail.booth2 !== null && showdetail.booth2 !== '') { myBooths += showdetail.booth2 + ', '; }
            if (showdetail.booth3 !== null && showdetail.booth3 !== '') { myBooths += showdetail.booth3 + ', '; }
            if (myBooths !== '') {
                myBooths = myBooths.slice(0, -2);
            }
            else {
                myBooths = 'Unassigned';
            }

            //build booth costs string
            let myBoothCost = '';
            if (boothpricing == undefined) {
                myBoothCost = "Booth Costs: ?????"
            }

            if (showdetail.booth1 === null && showdetail.booth2 === null && showdetail.booth3 === null) {
                myBoothCost = "$0.00";
            }
            else if (showdetail.booth1 !== null && showdetail.booth2 !== null && showdetail.booth3 !== null) {
                myBoothCost = currencyFormatter.format(boothpricing.priceTriple);
            }
            else if (showdetail.booth1 !== null && showdetail.booth2 !== null) {
                myBoothCost = currencyFormatter.format(boothpricing.priceDouble);
            }
            else if (showdetail.booth1 !== null) {
                myBoothCost = currencyFormatter.format(boothpricing.priceSingle);
            }

            //build pending booth moves string
            let myBoothMoveStr = '';
            if (showdetail.moveto_booth1 === null || showdetail.moveto_booth1 === '') {
                myBoothMoveStr = "No pending booth moves";
            }
            else {
                myBoothMoveStr = "booth " + showdetail.moveto_booth1;
                if (showdetail.moveto_booth2 !== null && showdetail.moveto_booth2 !== '') { myBoothMoveStr += ", " + showdetail.moveto_booth2; }
                if (showdetail.moveto_booth3 !== null && showdetail.moveto_booth3 !== '') { myBoothMoveStr += ", " + showdetail.moveto_booth3; }
                myBoothMoveStr = "Move pending to " + myBoothMoveStr;
            }

            //set up checkboxes
            if (showdetail.weblink === 'Yes') { setValue('formFields.showdetail.weblink', true); } else { setValue('formFields.showdetail.weblink', false); }
            if (showdetail.prereglist === 'Yes') { setValue('formFields.showdetail.prereglist', true); } else { setValue('formFields.showdetail.prereglist', false); }

            //format currency fields
            if (showdetail.weblinkamount !== '' && showdetail.weblinkamount !== null) { setValue('formFields.showdetail.weblinkamount', USDollar.format(showdetail.weblinkamount)); }
            if (showdetail.prereglistamount !== '' && showdetail.prereglistamount !== null) { setValue('formFields.showdetail.prereglistamount', USDollar.format(showdetail.prereglistamount)); }
            if (showdetail.sponsorshipamount !== '' && showdetail.sponsorshipamount !== null) { setValue('formFields.showdetail.sponsorshipamount', USDollar.format(showdetail.sponsorshipamount)); }
            if (showdetail.leadretrievalamount !== '' && showdetail.leadretrievalamount !== null) { setValue('formFields.showdetail.leadretrievalamount', USDollar.format(showdetail.leadretrievalamount)); }

            setState({
                ...state,
                selectedCustomerId: props.selectedCustomerId,
                selectedShowCode: props.selectedShowCode,
                selectedShowName: props.selectedShowName,
                showdetail: showdetail,
                boothpricing: boothpricing,
                boothStr: myBooths,
                boothCostsStr: myBoothCost,
                boothMoveStr: myBoothMoveStr,
                selectedServiceCodes: mySelectedServiceCodes,
                showPane: props.showPane,
                dataLoaded: true
            });

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });

    }

    var currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    function handleServiceCodesChange(value) {
        console.log(value);

        setState({
            ...state,
            selectedServiceCodes: value
        });
    }

    function handlePrintYellowForm() {
        let postdata = {};
        postdata.exhibitorid = props.selectedCustomerId;
        postdata.showcode = props.selectedShowCode;
        postdata.booth1 = getValues().formFields.showdetail.booth1;
        postdata.booth2 = getValues().formFields.showdetail.booth2;
        postdata.booth3 = getValues().formFields.showdetail.booth3;
        postdata.booth4 = getValues().formFields.showdetail.booth4;

        const key = "yellowform";
        message.loading({
            content: 'Downloading Yellow Form...',
            key,
            duration: 0
        });

        Axios.post(`/api/GenerateYellowForm`, postdata, {
            responseType: 'blob'
        }).then(response => {
            message.destroy(key);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = "yellowform.pdf";
            /*a.download = myFileName + ".csv";*/
            a.click();
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleViewAR() {

    }

    function handleBoothFurnishings() {

    }

    function handleSendETicket() {

    }

    function handleSendYellowForm() {
        console.log('here');
        setState({
            ...state,
            showSimpleSendEmailSlider: true
        });
    }

    function handleShowBadges() {

    }

    function handleSave() {
        //console.log(getValues().formFields.showdetail);
        //console.log(getValues().formFields.contacts);
        //console.log(hotelsBookedChecked);

        let showDetail = getValues().formFields.showdetail;
        let contacts = getValues().formFields.contacts;

        contacts.forEach((contact, index, array) => {
            if (hotelsBookedChecked.includes(contact.contact_id)) {
                //contact is checked
                if (contact.hotelsBooked === null) {
                    contact.hotelsBooked = '|' + showDetail.showcode.toString() + '|';
                }
                else if (contact.hotelsBooked.includes(showDetail.showcode.toString())) {
                    //already there, nothing further needed 
                }
                else {
                    //not found, add it
                    contact.hotelsBooked += '|' + showDetail.showcode.toString() + '|';
                }
            }
            else {
                //contact is not checked
                if (contact.hotelsBooked === null) {
                    //not in string, nothing further needed
                }
                else if (contact.hotelsBooked.includes(showDetail.showcode.toString())) {
                    //found, need to remove it. 1st, replace (showcode + '|') with '' which yields:
                    //|389| -> |
                    //|389|390| -> |390|
                    //|390|389| -> |390|
                    //|390|389|392| -> |390|392|

                    contact.hotelsBooked = contact.hotelsBooked.replace(showDetail.showcode.toString() + '|', "");

                    //now get rid of '|' if no other show codes found
                    if (contact.hotelsBooked === '|') {
                        contact.hotelsBooked = '';
                    }
                }
            }
        });

        //build out prereglistcategories
        let mySelectedServiceCodes = '';
        state.selectedServiceCodes.forEach((sc, index, array) => {
            mySelectedServiceCodes += '|' + sc;
        });
        if (mySelectedServiceCodes !== '') {
            mySelectedServiceCodes += '|';
        }
        showDetail.prereglistcategories = mySelectedServiceCodes;

        showDetail.corner = showDetail.corner === true ? 1 : 0;
        showDetail.slipsheet = showDetail.slipsheet === true ? 1 : 0;
        showDetail.sponsorship = showDetail.sponsorship === true ? 1 : 0;
        showDetail.caravanfreight = showDetail.caravanfreight === true ? 1 : 0;

        showDetail.weblink = showDetail.weblink === true ? 'Yes' : 'No';
        showDetail.prereglist = showDetail.prereglist === true ? 'Yes' : 'No';

        let postdata = {
            showdetail: showDetail,
            contacts: contacts
        };

        Axios.post(`/api/UpdateShowDetail`, postdata
        ).then(response => {
            if (response.data === true) {
                toast.success("Show Detail saved successfully!");
            }
            else {
                toast.error("Something went wrong!");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleUpdateBoothMoves() {

    }

    //Format price fields to USD using the locale, style, and currency.
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    console.log(state.showSimpleSendEmailSlider);

    return (
        <>
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={state.selectedShowName}
            onRequestClose={hidePane}
            width={'90%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <div className="row mb-3">
                            <div className="col-sm-12 col-md-12 col-lg-9">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>General Show Information</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Sales Status</label>
                                                <select className="form-control-custom" {...register("formFields.showdetail.salesstatus")}>
                                                    <option value="Yes">Yes</option>
                                                    <option value="Cancel">Cancel</option>
                                                    <option value="Interested">Interested</option>
                                                    <option value="No">No</option>
                                                    <option value="Till">Till</option>
                                                    <option value="Waitlist">Waitlist</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Badges Received</label>
                                                <select className="form-control-custom" {...register("formFields.showdetail.badgesreceived")}>
                                                    <option value=""></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label className="form-label">Show Video</label>
                                                <input type="text" name="showvideolink" {...register("formFields.showdetail.showvideolink")} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <div>&nbsp;</div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" name="slipsheet" {...register("formFields.showdetail.slipsheet")} checked={getValues().formFields.slipsheet} />
                                                    <label className="custom-control-label">Include on Slipsheet</label>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-6 col-xl-3">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Hotel Booked</span></Card.Header>
                                    <Card.Body>
                                        <Checkbox.Group
                                            style={{ width: "100%" }}
                                            value={hotelsBookedChecked}
                                            onChange={(checkedValues) => {
                                                setHotelsBookedChecked(checkedValues);
                                                console.log(checkedValues);
                                            }}
                                        >
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={getValues().formFields.contacts}
                                                renderItem={(item) => (
                                                    <List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Checkbox value={item.contact_id} />}
                                                            title={item.jsS_name}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </Checkbox.Group>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-sm-12 col-md-6 col-xl-3">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Booth Selections</span></Card.Header>
                                    <Card.Body>
                                        <table>
                                            <tbody>
                                                <tr><th style={{ verticalAlign: 'top' }}><label className="form-label">Booth 1:</label>&nbsp;</th><td><input type="text" name="booth1" {...register("formFields.showdetail.booth1")} className="form-control-custom" /></td></tr>
                                                <tr><th style={{ verticalAlign: 'top' }}><label className="form-label">Booth 2:</label>&nbsp;</th><td><input type="text" name="booth2" {...register("formFields.showdetail.booth2")} className="form-control-custom" /></td></tr>
                                                <tr><th style={{ verticalAlign: 'top' }}><label className="form-label">Booth 3:</label>&nbsp;</th><td><input type="text" name="booth3" {...register("formFields.showdetail.booth3")} className="form-control-custom" /></td></tr>
                                                <tr><th style={{ verticalAlign: 'top' }}><label className="form-label">Booth 4:</label>&nbsp;</th><td><input type="text" name="booth4" {...register("formFields.showdetail.booth4")} className="form-control-custom" /></td></tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" name="corner" {...register("formFields.showdetail.corner")} checked={getValues().formFields.corner} />
                                                            <label className="custom-control-label">Corner</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr><th style={{ verticalAlign: 'top' }}><label className="form-label">Booth Costs:</label>&nbsp;</th><td><label className="form-data-label">{state.boothCostsStr}</label></td></tr>
                                            </tbody>
                                        </table>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-6 col-xl-6">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Package Selections</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Package Type</label>
                                                <select className="form-control-custom" {...register("formFields.showdetail.packagetype")}>
                                                    <option value="Basic">Basic</option>
                                                    <option value="Custom">Custom</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" name="weblink" {...register("formFields.showdetail.weblink")} checked={getValues().formFields.weblink} />
                                                    <label className="custom-control-label">Web Link</label>
                                                </div>
                                                <label>
                                                Amount:&nbsp;<input className="inline-text-box" type="text" name="weblinkamount" placeholder="" {...register("formFields.showdetail.weblinkamount")} />
                                                </label>
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" name="prereglist" {...register("formFields.showdetail.prereglist")} checked={getValues().formFields.prereglist} />
                                                    <label className="custom-control-label">Pre Reg List</label>
                                                </div>
                                                <label>
                                                    Amount:&nbsp;<input className="inline-text-box" type="text" name="prereglistamount" placeholder="" {...register("formFields.showdetail.prereglistamount")} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Pre Reg List Categories</label>
                                                <Select mode="multiple" className="form-control-custom d-inline-block" onChange={handleServiceCodesChange} style={{ width: '100%', marginTop: 7 }} placeholder="Select one or more past shows" value={state.selectedServiceCodes || undefined} >
                                                    {getValues().formFields.servicecodes?.map((sc, idx) => <option key={idx} value={sc.code}>{sc.description}</option>)}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Invoice Note</label>
                                                <input type="text" name="invoicenote" {...register("formFields.showdetail.invoicenote")} className="form-control-custom" />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-6 col-xl-3">
                                <Card className="d2pCard mb-3">
                                    <Card.Header>Booth Moves</Card.Header>
                                    <Card.Body>
                                        <label className="form-label">{state.boothMoveStr}</label><br />
                                    <button className="btn btn-link me-3 float-right" onClick={handleUpdateBoothMoves}>{state.boothMoveStr === 'No pending booth moves' ? 'Create' : 'Update'}</button>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-sm-12 col-md-6 col-xl-6">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Lead Retrieval</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Lead Retrieval</label>
                                                <select className="form-control-custom" {...register("formFields.showdetail.leadretrievalstatus")}>
                                                    <option value=""></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Amount</label>
                                                <input type="text" name="leadretrievalamount" {...register("formFields.showdetail.leadretrievalamount")} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-8">
                                                <label className="form-label">Lead Retrieval Note</label>
                                                <input type="text" name="leadretrievalnote" {...register("formFields.showdetail.leadretrievalnote")} className="form-control-custom" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Contact</label>
                                                <input type="text" name="leadretrievalcontact" {...register("formFields.showdetail.leadretrievalcontact")} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-5">
                                                <label className="form-label">Email</label>
                                                <input type="text" name="leadretrievalemail" {...register("formFields.showdetail.leadretrievalemail")} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Phone</label>
                                                <input type="text" name="leadretrievalphone" {...register("formFields.showdetail.leadretrievalphone")} className="form-control-custom" />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-6 col-xl-3">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Caravan Freight</span></Card.Header>
                                    <Card.Body>
                                        <div className="form-group col-lg-12">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" name="caravanfreight" {...register("formFields.showdetail.caravanfreight")} checked={getValues().formFields.caravanfreight} />
                                                <label className="custom-control-label">Caravan Freight</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">Caravan Freight Note</label>
                                            <input type="text" name="caravanfreightnote" {...register("formFields.showdetail.caravanfreightnote")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-12">
                                            <label className="form-label">&nbsp;</label>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-12 col-md-6 col-xl-3">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Sponsorship</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" name="sponsorship" {...register("formFields.showdetail.sponsorship")} checked={getValues().formFields.sponsorship} />
                                                    <label className="custom-control-label">Sponsorship</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Amount</label>
                                                <input type="text" name="sponsorshipamount" {...register("formFields.showdetail.sponsorshipamount")} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-9">
                                                <label className="form-label">Note</label>
                                                <input type="text" name="sponsorshipnote" {...register("formFields.showdetail.sponsorshipnote")} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-12">
                                                <label className="form-label">&nbsp;</label>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <label className="form-label">Online Contract Status:&nbsp;</label>
                                {getValues().formFields.showdetail.onlinecontractstatus}
                            </div>

                            {/*<p></p>*/}

                            {/*<div className="col-sm-12 col-md-12 col-lg-12">*/}
                            {/*    <button className="btn btn-submit me-3 float-right" onClick={handlePrintYellowForm}>Print Yellow Form</button>*/}
                            {/*    <button className="btn btn-submit me-3 float-right" onClick={handleViewAR}>View AR</button>*/}
                            {/*    <button className="btn btn-submit me-3 float-right" onClick={handleBoothFurnishings}>Booth Furnishings</button>*/}
                            {/*    <button className="btn btn-submit me-3 float-right" onClick={handleSendETicket}>Send E-Ticket</button>*/}
                            {/*    <button className="btn btn-submit me-3 float-right" onClick={handleSendYellowForm}>Send Yellow Form</button>*/}
                            {/*    <button className="btn btn-submit me-3 float-right" onClick={handleShowBadges}>Show Badges</button>*/}
                            {/*</div>*/}
                        </div>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit me-3 float-right" onClick={handleSave}>Save</button>
                    <button className="btn btn-outline-primary me-3 float-right" onClick={handlePrintYellowForm}>Print Yellow Form</button>
                    <button className="btn btn-outline-primary me-3 float-right" onClick={handleViewAR}>View AR</button>
                    <button className="btn btn-outline-primary me-3 float-right" onClick={handleBoothFurnishings}>Booth Furnishings</button>
                    <button className="btn btn-outline-primary me-3 float-right" onClick={handleSendETicket}>Send E-Ticket</button>
                    <button className="btn btn-outline-primary me-3 float-right" onClick={handleSendYellowForm}>Send Yellow Form</button>
                    <button className="btn btn-outline-primary me-3 float-right" onClick={handleShowBadges}>Show Badges</button>

                    <button className="btn btn-submit margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
            </SlidingPane>
            {
                state.showSimpleSendEmailSlider &&
                <SimpleSendEmailSlider showPane={state.showSimpleSendEmailSlider} selectedCustomerId={state.selectedCustomerId} hidePane={() => setState({ ...state, showSimpleSendEmailSlider: false })} />
            }

        </>
    );
}