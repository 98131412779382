import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import Moment from 'moment';
import { dateWithNoTimezone } from './DateFormat';
import { useForm, Controller } from "react-hook-form";
import { Modal, Empty, Spin, Select, Alert, Table, Space } from 'antd';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';

export default function RunReportModal(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const { Option } = Select;

    const [state, setState] = useState({
        showModal: props.showModal,
        shows: [],
        selectMode: 'single',
        selectedShow: [],
        rptOption1Text: '',
        rptOption2Text: '',
        rptOption3Text: '',
        selectedOption: '',
        loading: true
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        console.log(props);

        var mySelectedReportOption = '';

        //if options included for report, set the first one as the default
        if (props.option1 !== null || props.option2 !== null || props.option3 !== null) {
            mySelectedReportOption = props.option1;
        }

        setState({
            ...state,
            loading: true
        });

        let postdata = {};
        Axios.post(`/api/GetActiveShowList`, postdata
        ).then(response => {
            let shows = response.data;

            setState({
                ...state,
                shows,
                showModal: true,
                rptOption1Text: props.option1,
                rptOption2Text: props.option2,
                rptOption3Text: props.option3,
                selectedOption: mySelectedReportOption,
                loading: false
            });
        }).catch(error => {
            console.log(error);
        });
    }, [props]);

    function closeModal() {
        //This prevents the modal from opening in the parent unless called upon again
        props.hideRunReportModal();

        setState({
            ...state,
            showModal: false
        });
    }

    function openModal() {
        setState({
            ...state,
            showModal: true
        });
    }

    const columns = [
        {
            dataIndex: 'showcode',
            key: 'showcode',
            width: 50,
        },
        {
            title: 'Show',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Show Dates",
            dataIndex: "showdates",
            render: (text, record) => (
                <span>{Moment(dateWithNoTimezone(record.startdate)).format("L")} - {Moment(dateWithNoTimezone(record.finishdate)).format("L")}</span>
            )
        }
    ];

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function onRunReport() {

        if (state.selectedShow.length === 0) {
            toast.error("You must select a show to report on");
            return;
        }
        
        props.setSelectedShowCode(props.reportName, state.selectedOption, state.selectedShow);
    }

    function handleSelectdShowChange(value) {
        setState({
            ...state,
            selectedShow: value
        });
    }

    function handleReportOptionChange(e) {
        const target = e.target;

        setState({
            ...state,
            selectedOption: e.target.value
        });
    }

    return (
        <>
            <Modal
                visible={state.showModal}
                title={state.modalTitle}
                onCancel={closeModal}
                footer={[
                    <button className="btn btn-submit" onClick={onRunReport}>
                        Run Report
                    </button>,
                    <span>&nbsp;&nbsp;</span>,
                    <button className="btn btn-outline-primary" onClick={closeModal}>
                        Close
                    </button>
                ]}
            >
                <h5>{props.reportName} Report</h5>
                Select D2P Show
                {state.loading ?
                    <ReactLoading className='tableLoading' type={"spokes"} color={'Navy'} width={30}
                        style={{ display: 'flex', alignItems: 'center', height: '100%', margin: '0 auto', width: '30px', fill: '#6FB246' }}
                    />
                    :
                    <Fragment>
                        {
                            state.shows.length > 0 ?
                                //<Select mode={state.selectMode} className="form-control-custom d-inline-block" onChange={handlePastShowsChange} style={{ width: '100%' }} placeholder="Please select one or more past shows" >
                                //    {state.shows.map((show, idx) => <option key={idx} value={show.showcode}>{show.name} <span>{Moment(dateWithNoTimezone(show.startdate)).format("L")} - {Moment(dateWithNoTimezone(show.finishdate)).format("L")}</span></option>)}
                                //</Select>

                                <>
                                    <Select mode={state.selectMode} className="form-control-custom d-inline-block" onChange={handleSelectdShowChange} style={{ width: '100%' }} placeholder="Please select one or more past shows" >
                                        {state.shows.map((show, idx) => <option key={idx} value={show.showcode}><div style={{ width: '50%', float: 'left' }}>{show.name}</div><div style={{ width: '50%', float: 'right' }}>{Moment(dateWithNoTimezone(show.startdate)).format("L")} - {Moment(dateWithNoTimezone(show.finishdate)).format("L")}</div></option>)}
                                    </Select>

                                    <div>&nbsp;</div>

                                    <div className="form-group form-inline col-lg-12" onChange={handleReportOptionChange}>
                                        {props.option1 ? <span><input type="radio" name="selectedOption" value={state.rptOption1Text} checked={state.selectedOption === state.rptOption1Text} />&nbsp;<label className="form-label">{state.rptOption1Text}</label>&nbsp;&nbsp;&nbsp;</span> : ''}
                                        {props.option2 ? <span><input type="radio" name="selectedOption" value={state.rptOption2Text} checked={state.selectedOption === state.rptOption2Text} />&nbsp;<label className="form-label">{state.rptOption2Text}</label>&nbsp;&nbsp;&nbsp;</span> : ''}
                                        {props.option3 ? <span><input type="radio" name="selectedOption" value={state.rptOption3Text} checked={state.selectedOption === state.rptOption3Text} />&nbsp;<label className="form-label">{state.rptOption3Text}</label>&nbsp;&nbsp;&nbsp;</span> : ''}
                                    </div>
                                </>
                                :
                                <Empty description={<p>No Shows Found</p>} />
                        }
                    </Fragment>
                }
                
            </Modal>
        </>
    );
}