import React, { useState, useEffect } from 'react';

import { Empty, List, Comment } from 'antd';

import Moment from 'moment';
import { getDialogueByParentId } from '../../services/WorklistService';

//import '../../css/sms.css';

export default function WorklistDialogueView(props) {
    const [dialogue, setDialogue] = useState([]);

    useEffect(() => {
        getDialogueByParentId(props.worklistParentId).then(data => {
            console.log(data);

            let tempDialogue = [];

            data.forEach(item => {

                let name = '';
                let content = '';

                if (item.dialogueContent.includes("Commented")) {
                    var splitDialogueContent = item.dialogueContent.split("Commented");
                    name = splitDialogueContent[0];
                    content = "Commented" + splitDialogueContent[1];
                } else if (item.dialogueContent.includes("Selected")) {
                    var splitDialogueContent = item.dialogueContent.split("Selected");
                    name = splitDialogueContent[0];
                    content = "Selected" + splitDialogueContent[1];
                } else {
                    content = item.dialogueContent;
                }

                tempDialogue.push({
                    author: item.username,
                    authorid: item.username,
                    content: <p>{name && <span className="color-primary">{name}</span>}{content}</p>,
                    //content: <p>{item.dialogueContent}</p>,
                    annotationcontent: item.dialogueContent,
                    datetime: Moment(item.dialogueDate).format("L LT"),
                    datemodified: Moment(item.dialogueDate).toDate(),
                    worklistid: item.worklist_ID,
                    worklistparentid: item.worklistParent_ID,
                    worklistdialogueid: item.worklistParent_ID
                });
            });

            setDialogue(tempDialogue);
        });

    }, []);


    const CommentList = ({ comments }) => (
        <List
            dataSource={comments}
            itemLayout="horizontal"
            renderItem={props => <Comment {...props} style={{borderBottom: '1px solid grey'}} />}
        />
    );


    return (
        <>
            {dialogue?.length > 0 ? <CommentList comments={dialogue} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Dialogue" />}
            {/*<div className="message-conversation-container" id='message-conversation-container'>
                <div className="imessage">
                    {dialogue.length > 0 ? dialogue.map((item, idx) => (
                        <Fragment key={idx}>
                            <div className="row"><small className="col" style={{fontSize: '.9rem'}}>{item.employeeId + ", "}{Moment(item.dialogueDate).utc().format("MM/DD/yyyy, h:mm a")}</small></div>
                            <p className={item.employeeId === userInfo.id ? 'from-me' : 'from-them'}>
                                {item.dialogueContent.split("Additional Comments")[0]}
                                <span className="text-icon">{item.dialogueContent.split("Additional Comments")[1] && 'Additional Comments' + item.dialogueContent.split("Additional Comments")[1]}</span>
                            </p>
                        </Fragment>
                    )) :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </div>
            </div>*/}
        </>
    );
}