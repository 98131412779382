import React, { useState, useEffect } from 'react'
import { Route, Navigate, withRouter, useParams, useLocation, Routes, useNavigate } from 'react-router-dom';
import { hot } from 'react-hot-loader/root'
import { activeRoute } from './services/SpaService';
import { toast } from '@rickylandino/react-messages';
import Login from './components/Auth/Login';
import { Layout } from './components/Layout';
import Modal from 'react-modal';
import { Modal as AntModal } from 'antd';
import CustomerView from './components/Customers/CustomerView';
import CustomerSearch from './components/Search/CustomerSearch';
import Globals from "./config/globals";
import Dashboard from './components/Dashboard/Dashboard';
import DashboardWF from './components/Dashboard/DashboardWF';
import QueryBuilder from './components/ReportingOutput/QueryBuilder';
import QueryBuilderResults from './components/ReportingOutput/QueryBuilderResults';
import ManagementDashboard from './components/Management/ManagementDashboard';
import WidgetCallSheets from './components/Widgets/WidgetCallSheets';
import { insertNewVersion } from './services/CachingService';
import { getVersion } from './services/ApiService';
import AddJSSProspect from './components/Prospects/AddJSSProspect';

import {
    RecoilRoot
} from 'recoil';

function App(props) {
    const location = useLocation();

    const [state, setState] = useState({
        refreshCache: false
    });

    useEffect(() => {
        activeRoute.next(location.pathname);

        Modal.setAppElement('#root');

        getVersionInformation();

        AntModal.destroyAll();
    });

    if (window.sessionStorage.getItem("userInfo")) {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
        Globals.userInfo = userInfo;
    }

    async function getVersionInformation() {
        let basePath = window.location.href;
        var baseUrl = basePath.replace(/\/+$/, '') + '/' + 'meta.json';

        const response = await fetch(baseUrl, {
            cache: 'no-store'
        });

        const meta = await response.json();

        getVersion().then(data => {
            if (`${meta.version}` !== data.cacheVersion) {
                toast.loading("Update in progress");
                setState({
                    refreshCache: true
                });

                insertNewVersion(meta.version).then(data => {
                    props.emptyCache();
                });
            } else if (window.localStorage.APP_VERSION !== data.cacheVersion && !props.isLatestVersion) {
                toast.loading("Update in progress");
                setState({
                    refreshCache: true
                });

                props.emptyCache();
            }
        });
    }

    return (

        <div className="nav-function-top">
            {
                location.pathname === '/' ? (
                    <Routes>
                        <Route path="/" element={<Login />} />
                    </Routes>
                ) :
                    <RecoilRoot>
                        <Layout>
                            <Routes>
                                {/*<Route path="/dashboard" element={<Dashboard />} />*/}
                                <Route path="/dashboard" element={<DashboardWF />} />
                                <Route path="/customerview" element={<CustomerView />} />
                                <Route path="/customersearch" element={<CustomerSearch />} />
                                <Route path="/querybuilder" element={<QueryBuilder />} />
                                <Route path="/queryresults" element={<QueryBuilderResults />} />
                                <Route path="/management-dashboard" element={<ManagementDashboard />} />
                                <Route path="/call-sheet-generator" element={<WidgetCallSheets />} />
                                <Route path="/addprospect" element={<AddJSSProspect />} />
                            </Routes>
                        </Layout>
                    </RecoilRoot>
            }
            {state.refreshCache && <div className="refresh-overlay" />}
        </div>
    );
}

export default process.env.NODE_ENV === "development" ? hot(App) : App;