import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import { toast, confirm } from '@rickylandino/react-messages';
import { Modal, Button } from 'antd';

export default function CustomerContactDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        mode: 'Add',
        usersListData: [],
        disableAltAddressFields: true
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contacts, setContacts] = useState([]);

    var isDirty = useRef(false);

    useEffect(() => {
        console.log(props.contacts[props.selectedIdx]);

        setContacts(props.contacts);

        const subscription = watch((value, { name, type }) => {
            if (type === 'change') {
                isDirty.current = true;
            }
        });

        LoadFormData();

        return () => subscription.unsubscribe();

    }, [props]);

    function LoadFormData() {
        var disableAltAddressFields;

        if (props.selectedIdx !== null) {
            setValue('formFields', props.contacts[props.selectedIdx]);

            setState({
                ...state,
                showPane: true,
                mode: props.mode,
                dataLoaded: true,
                disableAltAddressFields: !props.contacts[props.selectedIdx].use_alt_address
            });
        }
        else {
            setState({
                ...state,
                showPane: true,
                mode: props.mode,
                dataLoaded: true,
                disableAltAddressFields: true
            });
        }

        //contacts={contacts} selectedIdx={state.selectedIdx}
        //if (props.selectedItemId !== null) {
        //    Axios.get(`/api/GetContactInfoByContactId`, {
        //        params: {
        //            id: props.selectedItemId
        //        }
        //    }).then(response => {
        //        setValue('formFields', response.data);

        //        setState({
        //            ...state,
        //            showPane: true,
        //            mode: props.mode,
        //            dataLoaded: true,
        //            disableAltAddressFields: !response.data.use_alt_address
        //        });
        //    }).catch(error => {
        //        console.log(error);
        //    });
        //}
        //else {
        //    setState({
        //        ...state,
        //        showPane: true,
        //        mode: props.mode,
        //        dataLoaded: true,
        //        disableAltAddressFields: true
        //    });
        //}
    }

    function closeSlider() {
        if (isDirty.current) {
            confirm({
                title: "You are about to close this note and lose any changes you've made.",
                content: "Are you sure you would like continue?",
                buttons: ["Yes", "No"],
            }, (buttonPressed) => {
                if (buttonPressed === 'Yes') {
                    hidePane();

                    return 0;
                } else {
                    return 0;
                }
            });
        } else {
            hidePane();
        }
    }

    function hidePane() {
        //now update the parent list
        if (props.mode === 'Edit') {
            let contacts = props.contacts;
            const selectedIdx = contacts.findIndex(con => con.contact_id === props.selectedItemId);
            contacts[selectedIdx] = getValues().formFields;

            //for graceful, animated close
            setTimeout(() => props.updateTableDisplay(contacts, selectedIdx), 1000);
        }
        else {
            setTimeout(() => props.reloadContactsFromDB(), 1000);
        }

        setTimeout(() => {
            props.hidePane();
        }, 1000)

        setState({
            ...state,
            showPane: false
        });
    }

    function handleSave() {
        switch (props.callingComponent) {
            case 'CustomerContacts':
                handleSaveForCustomerContacts();

                break;
            case 'AddJSSProspect':
                handleSaveForAddJSSProspect();

                break;
            case 'ExhibitorDetailsSlider':
                handleSaveForExhibitorDetailsSlider()

                break;
            default:
            // code block
        }
    }

    function handleSaveForAddJSSProspect() {
        let message = '';

        //mandatory fields check
        if (getValues().formFields.jsS_name === '') {
            message += '&bull;&nbsp;A Contact Name must be provided</br>';
        }
        if (getValues().formFields.contact_type === '') {
            message += '&bull;&nbsp;A Contact Type must be provided</br>';
        }

        //for main, logistics, total package contacts - we only want one
        if (contacts && (getValues().formFields.contact_type === 'Main' || getValues().formFields.contact_type === 'Logistics' || getValues().formFields.contact_type === 'Total Package')) {
            for (var i = 0; i < contacts.length; i++) {
                console.log(contacts[i]);
                console.log(getValues().formFields.contact_type.toString());
                console.log(state.selectedItemId);

                //ignore the check for the currently selected index
                if ((contacts[i].contact_type === getValues().formFields.contact_type.toString()) && (props.selectedIdx !== i)) {
                    message += '&bull;&nbsp;Contact type \'' + getValues().formFields.contact_type + '\' already exists for this Exhibitor</br>';
                }
            }
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return;
        }
        
        props.handleSaveContact(getValues().formFields);
    }

    function handleSaveForExhibitorDetailsSlider() {
        console.log(getValues().formFields);
        props.handleSaveContact(getValues().formFields);
    }

    function handleSaveForCustomerContacts() {
        if (getValues().formFields.jsS_name === '' || getValues().formFields.contact_type === '') {
            toast.error("Mandatory Fields Missing - A Contact Name and Type Must Be Provided");
            return;
        }

        let myContactId = props.selectedItemId;
        let myJobshopDivision = getValues().formFields.division;

        if (props.selectedItemId === null) {
            myContactId = 0;
            myJobshopDivision = userInfo.jobShopDivision;
        }

        if (getValues().formFields.contact_type === 'Main' || getValues().formFields.contact_type === 'Logistics' || getValues().formFields.contact_type === 'Total Package') {
            Axios.get(`/api/CheckForOtherContactsOfSameType`, {
                params: {
                    company_id: props.selectedCustomerId,
                    jobshopdivision: myJobshopDivision,
                    contact_type: getValues().formFields.contact_type,
                    contact_id: myContactId
                }
            }).then(response => {
                if (response.data === true) {
                    toast.error("Contact type '" + getValues().formFields.contact_type + "' already exists for this exhibitor");
                    return;
                }

                if (props.selectedItemId !== null) {
                    UpdateCustomerContact();
                }
                else {
                    InsertCustomerContact();
                }

                /*showReasonForChangeModal();*/
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            if (props.selectedItemId !== null) {
                UpdateCustomerContact();
            }
            else {
                InsertCustomerContact();
            }
            //showReasonForChangeModal();
        }
    }

    function InsertCustomerContact() {
        let postdata = getValues().formFields;

        postdata.company_id = props.selectedCustomerId;
        postdata.division = userInfo.jobShopDivision;

        console.log(postdata);

        Axios.post('/api/InsertCustomerContact', postdata
        ).then(async response => {
            hidePane();
            toast.success('Contact successfully added');

        }).catch(error => {
            console.log(error);
        });
    }

    function UpdateCustomerContact() {
        let postdata = getValues().formFields;

        Axios.post('/api/UpdateCustomerContact', postdata
        ).then(async response => {
            showReasonForChangeModal();

        }).catch(error => {
            console.log(error);
        });
    }

    function handleReceiveEmailedInvoicesChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
/*        const name = target.name;*/

        setValue('formFields.receiveEmailedInvoices', value);

        setState({
            ...state,
        });
    }

    function handleAltMailingAddressChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
/*        const name = target.name;*/

        setValue('formFields.use_alt_address', value);

        setState({
            ...state,
            disableAltAddressFields: !value
        });
    }

    function showReasonForChangeModal() {
        setIsModalOpen(true);
    };

    const handleModalOk = () => {
        if (getValues().formFields.change_reason !== '') {
            InsertReasonForChangeNote();
        }

        hidePane();
        toast.success('Contact changes successfully saved');
        setIsModalOpen(false);
    };

    function InsertReasonForChangeNote() {
        let postdata = {};
        postdata.CustId = props.selectedCustomerId;
        postdata.note = 'Reason: ' + getValues().formFields.change_reason;
        postdata.note_date = new Date();;
        postdata.division = userInfo.jobShopDivision;
        postdata.type = 'Field Chg';

        Axios.post('/api/InsertCustomerNote', postdata
        ).then(async response => {
            hidePane();

        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={state.mode === 'Add'? 'Add New Contact' : 'Edit Contact'}
            onRequestClose={closeSlider}
            width={'65%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <Fragment>
                            <form className="">
                                <div className="row">
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Contact Type</label><br />
                                        <select className="form-control-custom" {...register("formFields.contact_type")}>
                                            <option value="Accounting">Accounting</option>
                                            <option value="Alt Sales">Alt Sales</option>
                                            <option value="Main">Main</option>
                                            <option value="Logistics">Logistics</option>
                                            <option value="MSO">MSO</option>
                                            <option value="Online">Online</option>
                                            <option value="RFQ">RFQ</option>
                                            <option value="Total Package">Total Package</option>   
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-10"></div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Contact Name</label>
                                        <input type="text" name="jsS_name" {...register("formFields.jsS_name")} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Title</label>
                                        <input type="text" name="title" {...register("formFields.title")} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Email</label>
                                        <input type="text" name="email" {...register("formFields.email")} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Phone</label>
                                        <input type="text" name="jsS_name" {...register("formFields.phone")} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Extension</label>
                                        <input type="text" name="ext" {...register("formFields.ext")} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Fax</label>
                                        <input type="text" name="fax" {...register("formFields.fax")} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Cell</label>
                                        <input type="text" name="cell" {...register("formFields.cell")} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">&nbsp;</label>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="receiveEmailedInvoices" {...register("formFields.receiveEmailedInvoices")} checked={getValues().formFields.receiveEmailedInvoices} onChange={handleReceiveEmailedInvoicesChange} />
                                            <label className="custom-control-label">Receive Emailed Invoices</label>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-12"><hr /></div>

                                    <div className="form-group col-lg-12">
                                        {/*<label className="form-label">&nbsp;</label>*/}
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" name="use_alt_address" {...register("formFields.use_alt_address")} checked={getValues().formFields.use_alt_address} onChange={handleAltMailingAddressChange} />
                                            <label className="custom-control-label">Use The Following Alternate Mailing Address</label>
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Company Name</label>
                                        <input type="text" name="alt_company_name" {...register("formFields.alt_company_name")} className="form-control-custom" disabled={state.disableAltAddressFields} />
                                    </div>
                                    <div className="form-group col-lg-8"></div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Street 1</label>
                                        <input type="text" name="alt_street1" {...register("formFields.alt_street1")} className="form-control-custom" disabled={state.disableAltAddressFields} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Street 2</label>
                                        <input type="text" name="alt_street2" {...register("formFields.alt_street2")} className="form-control-custom" disabled={state.disableAltAddressFields} />
                                    </div>
                                    <div className="form-group col-lg-4"></div>

                                    <div className="form-group col-lg-3">
                                        <label className="form-label">City</label>
                                        <input type="text" name="alt_city" {...register("formFields.alt_city")} className="form-control-custom" disabled={state.disableAltAddressFields} />
                                    </div>
                                    <div className="form-group col-lg-1">
                                        <label className="form-label">State</label>
                                        <input type="text" name="alt_state" {...register("formFields.alt_state")} className="form-control-custom" disabled={state.disableAltAddressFields} />
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Zip</label>
                                        <input type="text" name="alt_zip" {...register("formFields.alt_zip")} className="form-control-custom" disabled={state.disableAltAddressFields} />
                                    </div>
                                    <div className="form-group col-lg-2">
                                        <label className="form-label">Country</label>
                                        <input type="text" name="alt_country" {...register("formFields.alt_country")} className="form-control-custom" disabled={state.disableAltAddressFields} />
                                    </div>
                                    <div className="form-group col-lg-4"></div>
                                </div>
                            </form>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button id="btnSaveChanges" onClick={handleSave} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>Save Changes</button>
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={closeSlider}>Close</button>
                </div>
            </div>


            <Modal title="Enter Reason For Change"
                visible={isModalOpen}
                zIndex={111111}
                footer={[
                    <Button key="submit" type="primary" onClick={handleModalOk}>
                        Submit
                    </Button>,
                ]}
                >
                <div className="form-group col-lg-12">
                    <label className="form-label">Reason</label>
                    <textarea style={{ resize: 'none' }} name="change_reason" cols="40" rows="5" {...register("formFields.change_reason")} className="form-control-custom"></textarea>
                </div>
            </Modal>
        </SlidingPane>
    );
}